const m = require('air-vue-model/model')()
const orderCheck = require('../models/orderChecks')
m.name = 'order'
m.url = 'orders'
m.pk = 'uuid'

m.pagination._page_size = 20

m.payment_object = [
    {value: 'commodity', name: 'Товар'},
    {value: 'excise', name: 'Подакцизный товар'},
    {value: 'job', name: 'Работа'},
    {value: 'service', name: 'Услуга'},
    {value: 'payment', name: 'Платеж'},
    {value: 'deposit', name: 'Депозит'},
    {value: 'another', name: 'Другое'},
]

m.payment_method = [
    {value: 'full_prepayment', name: 'Полная предоплата'},
    {value: 'prepayment', name: 'Предоплата'},
    {value: 'advance', name: 'Аванс'},
    {value: 'full_payment', name: 'Полный расчет'},
    {value: 'partial_payment', name: 'Частичная оплата'},
    {value: 'credit', name: 'В кредит'},
    {value: 'credit_payment', name: 'Оплата кредита'},
]

m.routes = [
    {name: 'list', component: require('../main/views/orders/Orders')},
    {name: 'item', component: require('../main/views/orders/Order'), single: true},
    {name: 'report', component: require('../main/views/orders/report/OrdersReport'), path: 'order_report'},
]
m.default = {
    number: null,
    company: null,
    items: [],
    client: null,
    delivery_date: null,
    date: null,
    delivery_address: null,
    contact: null,
    worker: null,
    checkout: null,
    comment: null,
    checks: [],
}

m.loadList = (params = {}, settings = {}) => {
    const _newParams = {...params}
    if (m.filters.start_date)
        _newParams.start_date = window.moment(m.filters.start_date).utc().format('YYYY-MM-DD HH:mm:ss')
    if (m.filters.end_date)
        _newParams.end_date = window.moment(`${m.filters.end_date} 23:59:59`).utc().format('YYYY-MM-DD HH:mm:ss')
    return m._loadList(_newParams, settings)
}

m.filters = {
    start_date: null,
    end_date: null,
    number: null,
    client: null,
    company: null,
    worker: null,
    has_fiscalized_check: null,
    has_declined_check: null,
    has_waiting_check: null
}

m.report = {
    filters: {
        start_date: null,
    },
    result: null
}

m.loading = false

m.setWorker = (uuid, worker) => {
    // return m.sendPutSingle('set_worker', uuid, {worker: worker})
    //  console.log(uuid);
    //  console.log({worker: worker});
    //  return m.send('set_worker', uuid, {worker}, 'put').then(res => {
    //      console.log(res);
    //  })
}

m.loadReport = (dates, companies) => {
    m.loading = true
    m.sendPost('report', {
        "start_date": dates[0],
        "end_date": dates[1],
        "companies": companies,
    }).then(res => {
        m.report.result = res
        m.loading = false
    }).catch(() => {
        m.loading = false
    })
}

module.exports = m