<template>
    <vs-input block :value="currentValue" @input="$emit('input', $event)"
              :label-placeholder="label" class="body-2"
              @click="inputClick"
              @focus="focus"
              @change="$emit('change', currentValue)"
              :readonly="readonly"
              :disabled="readonly"
              @blur="$emit('blur')"
              @click-icon="$emit('click-icon')"
              :type="type"
              :visible-password="visiblePassword"
              :loading="loading"
              :color="color"
              :shadow="shadow"
              :state="error && clicked ? 'danger' : state"
              :rules="rules"
              :placeholder="placeholder">
        <template #message-danger v-if="!valid && clicked">
            {{ error }}
        </template>
        <template v-slot:icon v-if="icon">
            <font-awesome-icon :icon="icon"/>
        </template>
    </vs-input>
</template>

<script>
export default {
    name: 'AirTextField',
    props: {
        placeholder: String,
        label: String,
        value: [String, Number],
        icon: [String, Array],
        clickIcon: Function,
        type: String,
        color: String,
        state: String,
        rules: Array,
        readonly: Boolean,
        loading: Boolean,
        shadow: Boolean,
        visiblePassword: Boolean
    },
    data: () => ({
        clicked: false,
        valid: false
    }),
    methods: {
        inputClick() {
            this.clicked = true
            this.$emit('click')
        },
        focus() {
            this.clicked = true
            this.$emit('focus')
        },
    },
    created() {

    },
    computed: {
        error() {
            for (const el in this.rules) {
                const message = this.rules[el](this.value)
                if (message !== true) {
                    this.valid = false
                    return message
                }
            }
            this.valid = true
        },
        currentValue() {
            if (this.value === null)
                return ''
            return this.value
        }
    }
}
</script>

<style>
.vs-input__label {
    max-width: 250px;
}

</style>