<template>
    <v-layout column class="ma-0">
        <v-menu
            ref="menu"
            v-model="menu"
            :close-on-content-click="false"
            transition="scale-transition"
            max-width="290px"
            nudge-top="-10px"
            min-width="290px"
        >
            <template v-slot:activator="{ on }">
                <v-text-field
                    :flat="flat"
                    :solo="solo"
                    :background-color="backgroundColor"
                    :rules="rules"
                    :clearable="clearable"
                    :value="formatRow"
                    persistent-hint
                    @click:clear="$emit('input', null)"
                    :label="label"
                    :hide-details="hideDetails"
                    :style="`max-width: ${width}; ${flat ? 'border-radius: 6px' : ''}`"
                    :dense="dense"
                    :outlined="outlined"
                    v-on="on"
                    class="pa-0"
                    readonly
                >
                </v-text-field>
            </template>
            <v-card>
                <v-row no-gutters>
                    <v-date-picker v-if="selectedType === 'date'" :min="min" :max="max"
                                   :range="range" locale="ru-RU" :no-title="!time"
                                   v-model="dates" @change="emitDates" :first-day-of-week='1'
                                   color="primary"></v-date-picker>
                    <v-time-picker
                        v-else
                        @change="emitTime"
                        v-model="time"
                        format="24hr"
                    ></v-time-picker>
                </v-row>
            </v-card>
        </v-menu>
    </v-layout>
</template>

<script>

export default {
    mixins: [],
    props: {
        width: {
            type: String,
            default: '100%'
        },
        rules: Array,
        value: [String, Array],
        label: String,
        prependIcon: {
            type: String,
            default: 'mdi-calendar'
        },
        backgroundColor: String,
        hideDetails: {
            type: Boolean,
            default: true
        },
        clearable: Boolean,
        flat: Boolean,
        solo: Boolean,
        outlined: {
            type: Boolean,
            default: true
        },
        dense: {
            type: Boolean,
            default: true
        },
        range: Boolean,
        min: String,
        max: String,
        withTime: Boolean,
    },
    name: "DatePickerComponent",

    data: () => ({
        menu: false,
        selectedType: 'date',
        dates: null,
        time: null
    }),
    methods: {
        clear() {
            if (this.range) {
                this.$emit('input', [])
                this.$emit('change', [])
            } else {
                this.$emit('input', null)
                this.$emit('change', null)
            }
        },
        emitDates(val) {
            if (this.range) {
                if (val[0] > val[1])
                    this.dates = [val[1], val[0]]
            }
            if (!this.withTime) {
                this.$emit('input', this.dates)
                this.$emit('change', this.dates)
                this.menu = false
            } else {
                this.selectedType = 'time'
            }
        },
        emitTime() {
            this.emitDateTime()
            this.menu = false
        },
        emitDateTime() {
            const time = this.time || '00:00:00'
            if (!this.dates) {
                this.$emit('input', null)
                this.$emit('change', null)
            } else {
                let row = this.dates + ' ' + time
                row = moment(row, 'YYYY-MM-DD HH:mm:ss').utc().format('YYYY-MM-DD HH:mm:ss')
                this.$emit('input', row)
                this.$emit('change', row)
            }

        },
        formatDate(date) {
            if (!date) return '';
            date = date.split(' ');
            return window.moment.utc(date[0]).local().format('DD.MM.YYYY')
        },
        setDatesAndTimes() {
            if (this.withTime) {
                if (!this.value) {
                    this.dates = null
                    this.time = null
                } else {
                    let value = moment.utc(this.value, 'YYYY-MM-DD HH:mm:ss').local().format('YYYY-MM-DD HH:mm:ss')
                    value = value.split(' ')
                    this.dates = value[0]
                    this.time = value[1]
                }
            } else {
                if (this.range) {
                    this.dates = !this.value || this.value.length < 2 ? [] : [this.value[0], this.value[1]]
                } else
                    this.dates = this.value
            }
        },
    },
    created() {
        this.setDatesAndTimes()
    },
    computed: {
        formatRow() {
            if (this.withTime) {
                const time = this.time || ''
                return this.formatDate(this.dates) + ' ' + time
            } else {
                if (this.range)
                    if (this.dates.length !== 0) {
                        if (!this.dates[0] && !this.dates[1])
                            return 'Не указан период'
                        return `${this.formatDate(this.dates[0])} - ${this.formatDate(this.dates[1])}`
                    } else
                        return 'Не указан период'
                return this.formatDate(this.dates)
            }
        }
        // showClearable() {
        //     if (this.clearable) {
        //         if (this.range && this.value.length > 0)
        //             return true
        //         else if (!this.range && this.value)
        //             return true
        //     }
        //     return false
        // },
        // timeValue() {
        //     if (!this.time || !this.currentValue)
        //         return null
        //     const values = this.currentValue.split(' ')
        //     return values[1]
        // },
        // currentValue() {
        //     if (this.time && this.value) {
        //         return moment.utc(this.value).local().format('YYYY-MM-DD HH:mm:ss')
        //     }
        //     return this.value
        // },
    },
    watch: {
        menu(val) {
            if (!val) {
                if (this.range && this.dates.length === 1) {
                    this.dates = [this.dates[0], this.dates[0]]
                    this.$emit('input', this.dates)
                    this.$emit('change', this.dates)
                }
            } else {
                this.selectedType = 'date'
            }
        },
        value() {
            this.setDatesAndTimes()
        }
    }
}
</script>

<style scoped>

</style>
