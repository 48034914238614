<template>
    <v-col class="pa-0 pb-5" style="position: relative">
        <v-progress-linear :active="model.loadings.save || model.loadings.destroy"
                           color="primary" indeterminate
                           style="position: absolute; top: -20px"></v-progress-linear>
        <v-col class="pa-0 px-5">
            <v-row align="center" class="mt-5" no-gutters>
                <just-btn :loading="saveLoading" @click="saveItem">Сохранить</just-btn>
                <v-spacer></v-spacer>
            </v-row>
            <air-card class="mt-5">
                <air-form v-model="valid" class="mt-5">
                    <slot name="form" v-bind:item="item"></slot>
                </air-form>
                <slot name="item" v-bind:item="item"></slot>
            </air-card>
            <air-card class="mt-5 pt-3">
                <user-company-list></user-company-list>
            </air-card>
        </v-col>
    </v-col>
</template>

<script>
import AirItemActionsTemplate from '../../../components/template/actions/AirItemActionsTemplate'
import AirListTemplate from '../../../components/template/list/AirListTemplate'
import UserCompanyList from './userCompanies/UserCompanyList'


export default {
    name: 'CompanyItemTemplate',
    components: {
        AirItemActionsTemplate,
        AirListTemplate,
        UserCompanyList
    },
    props: {
        model: Object
    },
    data: () => ({
        valid: false,
        saveLoading: false,
        deleteLoading: false,
        workersDialog: true,
        addUserDialog: false

    }),
    methods: {
        saveItem() {
            this.saveLoading = true
            const item = this.model.item
            const data = {
                head_company: this.item.head_company || null,
                email: item.email,
                inn: item.inn,
                name: item.name,
                payment_address: item.payment_address,
                sno: item.sno
            }
            let promise
            let created = false
            if (this.$route.params.companyInn === 'new') {
                promise = this.model.create(data)
                created = true
            } else {
                promise = this.model.update(data)
            }
            // const {promise, created} = this.model.updateOrCreate(data)
            promise.then(res => {
                this.$snackbar.success('Сохранено')
                if (created)
                    this.$router.push({
                        name: `${this.model.name}Item`,
                        params: {
                            [this.model.routeParam]: res[this.model.pk]
                        }
                    }).catch(() => {
                    })
                this.saveLoading = false
            }).catch(() => {
                this.saveLoading = false
            })
        },
        deleteItem() {
            this.deleteLoading = true
            this.model.destroy().then(() => {
                this.$router.push({
                    name: `${this.model.name}List`
                }).catch(() => {
                })
            }).catch(() => {
                this.deleteLoading = false
            })
        },
        getWorkers() {
            this.$userCompany.loadList({company: this.$company.item.inn})
        }

    },
    created() {
        this.getWorkers()
    },
    computed: {
        item() {
            return this.model.item
        }
    }
}
</script>

<style scoped>

</style>