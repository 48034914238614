<template>
  <air-item-template :model="$checkout">
    <template v-slot:item="{ item }">
      <just-text-field
        class="mb-3 mt-5"
        label="Наименование"
        v-model="item.name"
        :rules="[$rules.required]"
      ></just-text-field>
      <just-text-field
        class="mb-3"
        label="Логин"
        v-model="item.login"
        :rules="[$rules.required]"
      ></just-text-field>
      <just-text-field
        class="mb-3"
        label="Пароль"
        v-model="item.password"
        :rules="item.id ? [] : [$rules.required]"
      ></just-text-field>
      <just-text-field
        class="mb-3"
        label="Группа"
        v-model="item.group_code"
        :rules="[$rules.required]"
      ></just-text-field>
      <just-text-field
        @blur="item.registration_number = item.registration_number || null"
        label="Рег. номер ККТ"
        v-model="item.registration_number"
      ></just-text-field>
      <just-select
        class="mt-3"
        item-text="value"
        item-value="value"
        v-model="item.version"
        :items="$checkout.versions"
        label="Версия"
      ></just-select>
      <v-checkbox v-model="item.default" readonly label="По умолчанию" @click="setAsDefault" />
      <just-select
        v-if="!item.id"
        class="mt-3"
        item-text="name"
        item-value="inn"
        v-model="item.company"
        :items="$company.list"
        label="Компания"
      ></just-select>
      <v-row no-gutters align="center" v-if="item.id" class="mt-6 body-2">
        <v-icon size="25" :color="item.validated ? 'green' : 'red'"
          >mdi-circle</v-icon
        >
        <just-btn
          :disabled="!item.id || item.validated"
          :loading="loading"
          @click="validate"
          class="ml-4"
        >
          Проверить данные кассы
        </just-btn>
        <v-spacer></v-spacer>
        <div class="mr-2">Токен обновлен:</div>
        <just-data-text
          hide-defaults
          :date-time="item.token_received_at"
        ></just-data-text>
      </v-row>
    </template>
  </air-item-template>
</template>

<script>
import AirItemTemplate from "../../../components/template/item/AirItemTemplate";

export default {
  name: "Checkout",
  components: {
    AirItemTemplate,
  },
  data: () => ({
    loading: false,
  }),
  methods: {
    validate() {
      this.loading = true;
      this.$checkout
        .sendPostSingle("validate", this.$checkout.item.id)
        .then((res) => {
          this.$checkout.item.validated = true;
          this.$checkout.item.token_received_at = res.token_received_at;
          this.$snackbar.success("Данные кассы подтверждены");
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
          this.$snackbar.fail("Данные кассы не удалось подтвердить");
        });
    },
    setAsDefault() {
      this.loading = true;
      this.$checkout
          .sendPutSingle("set_default", this.$checkout.item.id, {default: !this.$checkout.item.default})
          .then((res) => {
            this.$checkout.item.default = !this.$checkout.item.default;
            this.$checkout.item.token_received_at = res.token_received_at;
            this.$snackbar.success("Значение \"по умолчанию\" обновлено");
            this.loading = false;
          })
          .catch(() => {
            this.loading = false;
            this.$snackbar.fail("Произошла ошибка");
          });
    }
  },
};
</script>

<style scoped></style>
