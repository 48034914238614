<template>
  <air-item-template :model="$nomenclature">
    <template v-slot:form="{ item }">
      <just-text-field
        label="Наименование"
        v-model="item.name"
        :rules="[$rules.required]"
      ></just-text-field>
      <just-text-field
        class="mt-3"
        label="Артикул"
        v-model="item.sku"
        :rules="[$rules.required]"
      ></just-text-field>
      <just-text-field
        class="mt-3"
        label="Ед. измерения"
        v-model="item.measurement_unit"
        :rules="[$rules.required]"
      ></just-text-field>
      <just-text-field
        type="number"
        class="mt-3"
        label="Цена"
        v-model="price"
        @input="$nomenclature.item.price = $currency.getNumber(price * 100)"
      ></just-text-field>
      <just-select
        class="mt-3"
        label="Ставка НДС"
        v-model="item.vat"
        :items="$nomenclature.vats"
        item-text="name"
        item-value="value"
      ></just-select>
      <just-select
        v-if="!item.id"
        class="mt-3"
        item-text="name"
        item-value="inn"
        v-model="item.company"
        :items="$company.list"
        label="Компания"
      ></just-select>
      <v-checkbox
        hide-details
        style="margin-left: -15px"
        v-model="item.vacant_position"
        label="Свободная позиция"
      ></v-checkbox>
      <v-checkbox
        hide-details
        style="margin-left: -15px"
        v-model="item.mark_processing_mode"
        label="Честный знак"
      ></v-checkbox>
    </template>
  </air-item-template>
</template>

<script>
import AirItemTemplate from "../../../components/template/item/AirItemTemplate";

export default {
  name: "Nomenclatures",
  components: {
    AirItemTemplate,
  },
  data: () => ({
    price: 0,
  }),
  methods: {},
  created() {
    this.price = this.$nomenclature.item.price / 100;
  },
};
</script>

<style scoped></style>
