<template>
    <div>
        <v-row class="ma-0 pa-0" no-gutters align="center">
            <v-row no-gutters align="end" class="ma-0 pa-0">
                <div>
                    <v-row no-gutters align="end" class="flex-nowrap" :class="{'primary--text': discount}">
                    <span class="font-weight-bold mr-1"
                        :class="{'display-1' : large, 'small-price': small, 'title': !large && !small}">
                        {{ $currency.getNumber(price - discount) }}
                    </span>
                        <air-icon :class="{'title': large, 'caption' : small, 'body-2': !large && !small}"
                                  :style="large ? 'margin-bottom: 2px' : small ? 'margin-bottom: 1px' : 'margin-bottom: 5px'"
                                  icon="ruble-sign"></air-icon>
                        <span
                            v-if="showUnit"
                            :style="large ? '' : 'margin-bottom: 2px'"
                            class="font-weight-bold" :class="{'title': large}"> /шт.</span>
                    </v-row>
                </div>
                <div v-if="discount" class="grey--text ml-2" :style="!large ? 'margin-bottom: 2px' : ''">
                    <v-row no-gutters align="center" style="text-decoration: line-through; position: relative">
                        <span :class="{'title': large}" class="mr-1">{{ price }}</span>
                    </v-row>
                </div>
            </v-row>
            <v-progress-circular
                v-if="$product.discountLoadingIds.indexOf(productId) > -1"
                class="ml-2" :width="1.5" size="20" color="grey" indeterminate
            ></v-progress-circular>
        </v-row>
    </div>
</template>

<script>
export default {
    name: 'PriceView',
    props: {
        large: Boolean,
        small: Boolean,
        price: {
            type: Number,
            default: 0
        },
        discount: {
            type: Number,
            default: 0
        },
        productId: Number,
        showUnit: {
            type: Boolean,
            default: true
        }
    },
    data: () => ({}),
    methods: {}
}
</script>

<style scoped>
    .small-price {
        font-size: 15px;
    }
</style>