const m = require('air-vue-model/model')()
m.name = 'checkout'
m.url = 'checkouts'

m.routes = [
    {name: 'list', component: require('../main/views/checkouts/Checkouts')},
    {name: 'item', component: require('../main/views/checkouts/Checkout'), single: true},
]

m.versions = [{
    value: '4'
}, {
    value: '5'
}]

module.exports = m