<template>
    <v-col class="pa-5">
        <v-col no-gutters>
            <v-row class="pa-0 mb-3">
                <air-card>
                    <date-picker range v-model="dates"></date-picker>
                    <v-select
                        v-if="$company.list.length > 1"
                        v-model="companies"
                        :items="$company.list"
                        label="Выбрать компании"
                        item-value="inn"
                        item-text="name"
                        multiple
                        small-chips class="mt-3"
                        dense hide-details outlined
                    ></v-select>
                    <just-btn class="mt-3" @click="loadReport" :disabled="dates.length !== 2"
                              :loading="$order.loading">Загрузить отчет
                    </just-btn>
                </air-card>

            </v-row>
            <v-row class="pa-0" v-if="result">
                <just-card style="padding: 0 !important;">
                    <v-card style=" border-radius: 20px; box-shadow: none" class="px-0">
                        <v-toolbar
                            color="white"
                            flat
                            class="pa-0">
                            <template>
                                <v-tabs v-model="model" centered slider-color="primary">
                                    <v-tab :key="1" :href="`#tab-1`">
                                        Общий отчет
                                    </v-tab>
                                    <v-tab :key="2" :href="`#tab-2`">
                                        Детальный отчет
                                    </v-tab>
                                </v-tabs>
                            </template>
                        </v-toolbar>
                        <v-tabs-items v-model="model">
                            <general-report :item="result.general_report"></general-report>
                            <report-by-companies :items="result.report_by_companies"></report-by-companies>
                        </v-tabs-items>
                    </v-card>
                </just-card>
            </v-row>
        </v-col>

    </v-col>
</template>
<script>
import GeneralReport from "./GeneralReport";
import ReportByCompanies from "./reportByCompanies/ReportByCompanies";
import moment from "moment";
import DatePicker from "../../../../components/inputs/DatePicker";

export default {
    name: "OrdersReport",
    components: {ReportByCompanies, GeneralReport, DatePicker},
    data: () => ({
        companies: [],
        workers: [],
        clients: [],
        order_numbers: [],
        menu: false,
        dialog: false,
        loader: null,
        dates: [],
        model: 'kek'
    }),

    methods: {
        loadReport() {
            this.$order.loadReport(this.filterDates, this.companies)
        },
    },
    computed: {
        result() {
            return this.$order.report.result
        },
        filters() {
            return this.$order.report.filters
        },
        filterDates() {
            let dates = []
            if (this.dates.length === 2) {
                dates[0] = moment(this.dates[0] + ' 00:00:00').utc().format("YYYY-MM-DD HH:mm:ss")
                dates[1] = moment(this.dates[1] + ' 23:59:59').utc().format("YYYY-MM-DD HH:mm:ss")
                return dates
            }
        }
    },
    created() {
        this.dates = [
            moment.utc().local().format('YYYY-MM-DD'),
            moment.utc().local().format('YYYY-MM-DD'),
        ]
        this.loadReport()
    },

}
</script>

<style scoped>

</style>