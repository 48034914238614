export default {
    data: () => ({
        currentDateTime: null,
        lastDateInterval: null
    }),
    methods: {
        _getCurrentTime() {
            this.currentDateTime = moment.utc().format('YYYY-MM-DD HH:mm:ss')
        },
        getLastDateView(date, showText = true) {
            const _date = moment.utc(date)
            const currentTime = moment.utc(this.currentDateTime)
            const secondsDiff = currentTime.diff(_date, 'seconds')
            if (secondsDiff <= 60) {
                if (!showText) return `${secondsDiff} cек. назад`
                return `Последнее обновление ${secondsDiff} cек. назад`
            }
            const minutesDiff = currentTime.diff(_date, 'minutes')
            if (minutesDiff <= 60) {
                if (!showText) return `${minutesDiff} мин. назад`
                return `Последнее обновление ${minutesDiff} мин. назад`
            }
            if (_date.format('DD.MM.YYYY') === currentTime.format('DD.MM.YYYY')) {
                if (!showText) return `${_date.local().format('HH:mm')}`
                return `Последнее обновление в ${_date.local().format('HH:mm')}`
            }
            if (!showText) return `${_date.local().format('DD.MM.YYYY HH:mm')}`
            return `Последнее обновление ${_date.local().format('DD.MM.YYYY HH:mm')}`
        },
    },
    beforeDestroy() {
        if (this.lastDateInterval)
            clearInterval(this.lastDateInterval)
    },
    created() {
        this._getCurrentTime()
        this.lastDateInterval = setInterval(() => {
            this._getCurrentTime()
        }, 1000)
    }
}