var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-col',{staticClass:"pa-0",staticStyle:{"position":"relative"}},[_c('v-progress-linear',{staticStyle:{"position":"absolute","top":"-12px"},attrs:{"active":_vm.model.loadings.list || _vm.model.loadings.item,"indeterminate":"","color":"primary"}}),_c('v-row',{staticClass:"flex-nowrap px-5 my-3",attrs:{"no-gutters":"","align":"center"}},[_c('v-col',[_c('v-row',{attrs:{"no-gutters":"","align":"center"}},[(!_vm.sublist)?_c('air-header-text',[_vm._v(_vm._s(_vm._header))]):_c('air-title-text',[_vm._v(_vm._s(_vm._header))]),_c('div',{staticClass:"body-2 grey--text ml-5 mt-3"},[_vm._v("Количество: "+_vm._s(_vm.$order.pagination.total))])],1)],1),_c('v-col',{attrs:{"cols":"auto"}},[_c('v-row',{attrs:{"no-gutters":"","align":"center"}},[_c('orders-filters'),_vm._t("actions"),_c('just-btn',{attrs:{"disabled":"","many":"","to":_vm._to(),"floating":""}},[_vm._v("Создать")])],2)],1)],1),(_vm.$slots.filters)?_c('v-col',{staticClass:"pa-0 mb-5"},[_c('v-row',{attrs:{"no-gutters":"","align":"center"}},[_vm._t("filters")],2),_c('air-divider')],1):_vm._e(),_c('v-col',{staticClass:"pa-0 body-2 px-5"},[_c('air-card',{staticClass:"px-0 py-3"},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm._list,"items-per-page":20,"loading-text":"Заказы загружаются","no-data-text":"Заказов нет","hide-default-footer":""},on:{"click:row":_vm.openOrderDetail},scopedSlots:_vm._u([{key:"item.date",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-none"},[_vm._v(_vm._s(_vm.getCalendarDateTime(item.date)))])]}},{key:"item.sum",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-none"},[_vm._v(_vm._s(item.sum / 100))])]}},{key:"item.delivery_date",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-none"},[_vm._v(_vm._s(_vm.getCalendarDateTime(item.delivery_date)))])]}},{key:"item.check_count",fn:function(ref){
var item = ref.item;
return [_c('v-row',{staticClass:"flex-nowrap",attrs:{"no-gutters":"","align":"center"}},[_c('div',{staticClass:"green--text"},[_vm._v(_vm._s(item.fiscalized_check_count))]),_c('div',{staticClass:"caption grey--text",staticStyle:{"margin":"0 2px"}},[_vm._v("/")]),_c('div',{staticClass:"red--text"},[_vm._v(_vm._s(item.declined_check_count))]),_c('div',{staticClass:"caption grey--text",staticStyle:{"margin":"0 2px"}},[_vm._v("/")]),_c('div',{staticClass:"secondary--text"},[_vm._v(_vm._s(item.check_count))])])]}},{key:"item.worker",fn:function(ref){
var item = ref.item;
return [(item.worker && item.worker.user)?_c('div',[_vm._v(_vm._s(item.worker.user.first_name)+" "+_vm._s(item.worker.user.last_name)+" ")]):_vm._e()]}},{key:"item.last_check_information",fn:function(ref){
var item = ref.item;
return [_c('last-check-in-order-information',{attrs:{"center":"","order":item}})]}}])})],1)],1),(_vm.model && _vm.model.pagination.page_size !== 'all' && _vm.model.pagination.last_page >1)?_c('v-row',{staticClass:"px-5 mt-5 mb-3",attrs:{"no-gutters":"","align":"center","justify":"end"}},[_c('air-change-page',{attrs:{"last-page":_vm.model.pagination.last_page},model:{value:(_vm.model.pagination.page),callback:function ($$v) {_vm.$set(_vm.model.pagination, "page", $$v)},expression:"model.pagination.page"}})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }