const m = require('air-vue-model/model')()
m.name = 'client'
m.url = 'clients'
m.routes = [
    {name: 'list', component: require('../main/views/clients/Clients')},
    {name: 'item', component: require('../main/views/clients/Client'), single: true},
]

m.searchList = []

m.default = {
    name: null,
    inn: null,
    type: 1,
    company: null,
    contacts: [],
    delivery_addresses: []
}

module.exports = m