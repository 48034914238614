<template>
    <air-item-template :model="$order">
        <template v-slot:form="{item}">
            <v-card style="box-shadow: none !important;" class="mt-n5">
                <v-row no-gutters justify="center">
                    <just-btn style="min-width: 130px;" class="mx-2" @click="tab = 'goods'"
                              :color="tab === 'goods' ? 'primary' : 'white_button'">Товары
                    </just-btn>
                    <just-btn many style="min-width: 130px;" class="mx-2" @click="tab = 'about'"
                              :color="tab === 'about' ? 'primary' : 'white_button'">О заказе
                    </just-btn>
                    <just-btn many style="min-width: 130px;" class="mx-2" :disabled="!item.checks.length"
                              @click="tab = 'checks'"
                              :color="tab === 'checks' ? 'primary' : 'white_button'">Чеки ({{ item.checks.length }})
                    </just-btn>
                </v-row>
                <div v-if="tab === 'goods'">
                    <order-items-list :item="item"></order-items-list>
                </div>
                <div v-if="tab === 'about'">
                    <div v-if="item.uuid">
                        <div class="mb-2 secondary--text body-2">Последний чек</div>
                        <last-check-in-order-information class="mb-4" :order="item"></last-check-in-order-information>
                        <just-data-text label="Номер" :text="item.number"></just-data-text>
                        <just-data-text label="Дата" :date="item.date"></just-data-text>
                        <just-data-text label="Дата доставки" :date="item.delivery_date"></just-data-text>
                        <just-data-text label="Компания" :text="item.company.name"></just-data-text>
                        <just-data-text label="Касса" :text="item.checkout.name"></just-data-text>
                        <just-data-text label="Клиент" :text="item.client.name"></just-data-text>
                        <just-data-text label="Контакт" :text="item.contact ? item.contact.name : 'Отсутствует'"></just-data-text>
                        <just-data-text label="Работник" v-if="item.worker"
                                        :text="getWorkerFullName(item.worker.user)"></just-data-text>
                        <just-btn @click="openDialogSetWorker(item.worker, item.company)">Изменить работника</just-btn>
                        <air-dialog title="Изменить работника" v-model="dialogSetWorker" @close="$emit('input', false)"
                                    @click:outside="$emit('input', false)">
                            <div style="min-width: 500px">
                                <just-select class="my-3" label="Работник"
                                             :items="$userCompany.list"
                                             :item-text="val => `${val.user.first_name} ${val.user.last_name}`"
                                             item-value="id" v-model="worker"></just-select>
                                <just-btn @click="setWorker(item.uuid)">Отправить</just-btn>
                            </div>

                        </air-dialog>
                    </div>
                    <div v-if="!item.uuid">
                        <just-text-field label="Номер" textFieldClasses="mt-3"
                                         :textFieldVModel="item.number"></just-text-field>
                        <just-date-picker-popup textFieldLabel="Дата" textFieldClasses="mt-3"
                                                :textFieldVModel="item.date"
                                                :date="item.date"></just-date-picker-popup>
                        <just-date-picker-popup textFieldLabel="Дата доставки" textFieldClasses="mt-3"
                                                :textFieldVModel="item.delivery_date"
                                                :date="item.delivery_date"></just-date-picker-popup>
                        <just-select v-if="!item.id" class="mt-3" item-text="name" value="inn"
                                     v-model="item.company"
                                     :items="$company.list"
                                     label="Компания" :disabled="!!item.uuid"></just-select>
                        <just-select v-if="!item.id" class="mt-3" item-text="name" value="id"
                                     v-model="item.checkout"
                                     :items="$checkout.list"
                                     label="Касса" :disabled="!!item.uuid"></just-select>
                        <just-select v-if="!item.id" class="mt-3" item-text="name" value="id"
                                     v-model="item.client"
                                     :items="$client.list" label="Клиент" :disabled="!!item.uuid"></just-select>
                        <just-select v-if="!item.id" class="mt-3" item-text="name" value="id"
                                     v-model="item.contact"
                                     :items="$client.list"
                                     label="Контакт" :disabled="!!item.uuid"></just-select>
                        <just-select v-if="!item.id" class="mt-3" item-text="id" value="id"
                                     v-model="item.worker"
                                     :items="$userCompany.list"
                                     label="Работник"></just-select>
                    </div>
                </div>
                <div v-if="tab === 'checks'">
                    <order-checks-list></order-checks-list>
                </div>
                <div v-if="tab === 'about'">
                    <air-title-text>Комментарии</air-title-text>
                    <v-col v-if="item.comments.length" class="pa-0">
                        <v-row no-gutters v-for="item in item.comments" v-bind:key="item.id" class="mb-5">
                            <v-col class="pa-0">
                                <div class="secondary--text">

                                    <span v-if="item.user">{{ item.user.first_name + ' ' + item.user.last_name }}</span>
                                    •
                                    {{ getDate(item.created_at) }}
                                </div>
                                <div>{{ item.text }}</div>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-row no-gutters v-else class="mb-3">Комментариев нет.</v-row>
                    <div>
                        <air-textarea v-model="newCommentText" class="mb-3"
                                      label="Введите текст комментария"></air-textarea>
                        <just-btn @click="sentComment(item.uuid)">Добавить комментарий</just-btn>
                    </div>
                </div>
            </v-card>

        </template>
    </air-item-template>
</template>

<script>
import OrderItemsList from "./orderItems/OrderItemsList";
import AirItemTemplate from "../../../components/template/item/AirItemTemplate";
import OrderChecksList from "./orderCheck/OrderChecksList";
import {handleError} from "../../../vendor/handle";
import moment from "moment";
import LastCheckInOrderInformation from "./LastCheckInOrderInformation";

export default {
    name: 'Order',
    components: {
        AirItemTemplate,
        OrderItemsList,
        OrderChecksList,
        LastCheckInOrderInformation
    },
    data: () => ({
        dialogChecks: false,
        valid: false,
        model: 'DONT DELETE',
        tab: 'about',
        worker: null,
        dialogSetWorker: false,
        newCommentText: null
    }),
    methods: {
        async setWorker(uuid) {
            try {
                const res = await window.axios.put(`/orders/${uuid}/set_worker/`, {worker: this.worker})
                this.dialogSetWorker = false
                this.$snackbar.success('Работник обновлен')
                this.$order.loadItem(uuid)
            } catch (e) {
                this.$snackbar.fail(handleError(e.response.data))
            }
        },
        async sentComment(uuid) {
            try {
                const res = await window.axios.post(`order_comments/`, {order: uuid, text: this.newCommentText})
                this.newCommentText = null
                this.$order.item.comments.push(res.data)
                this.$snackbar.success('Комментарий добавлен')
            } catch (e) {
                this.$snackbar.fail(handleError(e.response.data))
            }

        },
        openDialogCheck(item) {
            this.selectedCheck = item
            this.dialogCheck = true
        },
        getPaymentType(payment) {
            if (payment === 1) {
                return 'Безналичный расчет'
            } else if (payment === 0) {
                return 'Наличный расчет'
            }
        },
        getWorkerFullName(user) {
            return user.first_name + ' ' + user.last_name
        },
        openDialogSetWorker(item, company) {
            if (item) {
                this.worker = item.id
            }
            this.dialogSetWorker = true
            this.$userCompany.loadList({company: company.inn, role: 2, active: true})
        },
        getDate(date) {
            return moment(date).calendar()
        }

    },
    computed: {},
    created() {
    }
}
</script>

<style scoped>


</style>