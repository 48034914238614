<template>
    <v-col class="pa-0">
        <div v-for="(el, index) in $check.reportData.checksCount" :key="index">
            <span class="body-2 grey--text text--darken-2 mr-2">{{ el.title }}:</span>
            <span>{{ item[el.key] }}</span>
        </div>
        <v-col class="pa-0" v-for="(el, index) in $check.reportData.values" :key="index">
            <div class="font-weight-bold my-2">{{ el.title }}</div>
            <div v-for="(val, valIndex) in $check.reportData.currentValues" :key="valIndex">
                <span class="body-2 grey--text text--darken-2 mr-2">{{ val.title }}:</span>
                <span v-if="val.key === 'count'">{{ item[el.key][val.key] }}</span>
                <span v-else>{{ item[el.key][val.key] /100}} ₽</span>
            </div>
        </v-col>
    </v-col>
</template>

<script>
export default {
    name: 'ReportView',
    props: {
        item: Object
    },
    data: () => ({}),
    methods: {}
}
</script>

<style scoped>

</style>