import { render, staticRenderFns } from "./Nomenclature.vue?vue&type=template&id=73dcf1a0&scoped=true&"
import script from "./Nomenclature.vue?vue&type=script&lang=js&"
export * from "./Nomenclature.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "73dcf1a0",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
installComponents(component, {VCheckbox})
