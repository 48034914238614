<template>
  <v-col class="pa-0">
    <div
      v-if="label"
      class="caption mb-1 secondary--text"
      style="line-height: 0.75rem"
    >
      {{ label }}
    </div>
    <v-select
      class="pl-0"
      style="border-radius: 6px"
      :items="items"
      append-icon="fa-angle-down"
      :clearable="clearable"
      :background-color="color"
      :item-text="itemText"
      :item-value="itemValue"
      :value="value"
      :disabled="disabled"
      :multiple="multiple"
      :chips="chips"
      no-data-text="Позиции для выбора отсутствуют"
      @input="$emit('input', $event)"
      @change="$emit('change', $event)"
      filled
      flat
      solo
      hide-details="auto"
      dense
    ></v-select>
  </v-col>
</template>

<script>
export default {
  name: "JustSelect",
  props: {
    items: Array,
    value: [Number, String, Object, Boolean, Array],
    label: String,
    clearable: Boolean,
    color: {
      type: String,
      default: "#f5f5f5",
    },
    itemText: {
      type: [String, Function],
      default: (val) => val.name,
    },
    itemValue: {
      type: [String, Array, Function],
      default: "id",
    },
    simpleValues: Boolean,
    disabled: Boolean,
    multiple: Boolean,
    chips: Boolean,
  },
  data: () => ({
    val: "1",
  }),
  methods: {},
  computed: {},
  created() {},
};
</script>

<style scoped></style>
