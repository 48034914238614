<template>
    <v-btn
        :loading="loading"
        :class="{'mx-2': many, 'ma-0': noGutters, 'px-2': !noPadding && !small, 'px-1': !noPadding && small, 'rounded-lg text-none font-weight-bold body-2': true}"
        style="border-radius: 6px"
        :color="color"
        :small="small"
        :to="to"
        :height="height"
        :width="width"
        :depressed="depressed"
        :disabled="disabled"
        :outlined="outlined"
        @click="$emit('click')" :block="block">
        <air-icon :class="{'mr-2': $slots.default, 'caption': small}" :icon="icon" v-if="icon && left"></air-icon>
        <v-row no-gutters align="center" justify="center" :class="{'caption': small}" class="flex-nowrap">
            <slot v-if="$slots.default"></slot>
        </v-row>
        <air-icon :class="{'ml-2': $slots.default, 'caption': small}" :icon="icon"
                  v-if="icon && !left && !center"></air-icon>
        <air-icon :class="{'caption': small}" :icon="icon" v-if="icon && center"></air-icon>
    </v-btn>
</template>

<script>


export default {
    name: 'JustBtn',
    props: {
        depressed: {
            type: Boolean,
            default: true
        },
        color: {
            type: String,
            default: 'primary'
        },
        height: {
            type: [String, Number],
            default: '45px'
        },
        width: [String, Number],
        to: [Object, String],
        left: Boolean,
        center: Boolean,
        border: Boolean,
        loading: Boolean,
        noGutters: Boolean,
        disabled: Boolean,
        block: Boolean,
        noPadding: Boolean,
        small: Boolean,
        icon: String,
        many: Boolean,
        outlined: Boolean
    },
    data: () => ({}),
    methods: {},
    computed: {
        currentWidth() {
            if (this.width)
                return this.width
            if (this.icon && !this.$slots.default)
                return this.small ? '28px' : '38px'
        },
        currentHeight() {
            if (this.height)
                return this.height
            return this.small ? '28px' : '38px'
        },
        style() {
            return `width: ${this.currentWidth}; min-width: ${this.currentWidth};
                height: ${this.currentHeight}; max-height: ${this.currentHeight} !important; `
        }
    }
}
</script>

<style scoped>
.v-btn:not(.v-btn--round).v-size--default {
    min-width: unset ;
}

</style>