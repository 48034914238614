<template>
    <air-list-template :model="$check" header="checks">
        <template v-slot:item="{item}">
            <v-row no-gutters align="center">
                <div class="font-weight-bold">{{ item.number }} <span class="ml-1">{{ parseDate(item.date) }}</span>
                </div>
                <v-spacer></v-spacer>
                <div>{{ parseWithoutSeconds(item.delivery_date) }}</div>
            </v-row>
            <v-row no-gutters align="center" class="mt-0">
                <div>{{ item.company.name }}</div>
                <v-spacer></v-spacer>
                <div>{{ item.client.name }}</div>
            </v-row>
          <pre>{{item}}</pre>
        </template>
    </air-list-template>

</template>

<script>
import AirListTemplate from "../../../components/template/list/AirListTemplate";

export default {
    name: 'OrderChecks',
    components: {
        AirListTemplate
    },
    data: () => ({}),
    methods: {
        parseDate(date) {
            return moment(date).local().format('DD-MM-YYYY HH:mm:ss')
        },
        parseWithoutSeconds(date) {
            return moment(date).local().format('DD-MM-YYYY')
        }
    },
}
</script>

<style scoped>

</style>