<template>
    <v-col class="pa-0">
        <div v-if="label" class="caption mb-1 secondary--text" style="line-height: 0.75rem;">{{ label }}</div>
        <v-textarea
            style="border-radius: 6px !important;"
            :placeholder="placeholder" background-color="#F5F7F8"
            hide-details class="rounded-lg" flat solo
            :value="value" @change="$emit('input', $event)">
        </v-textarea>
    </v-col>

</template>

<script>
import AirDescriptionText from "@/components/texts/AirDescriptionText";

export default {
    name: 'AirSelect',
    components: {
        AirDescriptionText
    },
    props: {
        value: String,
        label: String,
        placeholder: String
    },
    data: () => ({}),
    methods: {}
}
</script>

<style scoped>

</style>