<template>
    <v-row no-gutters class="flex-nowrap" :justify="center ? 'center' : 'start'" align="center" v-if="lastCheck">
        <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
                <v-icon v-on="on" style="border-radius: 6px; padding: 3px" :class="checkType.color" color="white"
                        size="15">
                    {{ checkType.icon }}
                </v-icon>
            </template>
            <span>{{ checkType.text }}</span>
        </v-tooltip>

        <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
                <v-icon v-on="on" class="ml-3" style="border-radius: 6px; padding: 3px" :class="checkStatus.color"
                        color="white"
                        size="15">
                    {{ checkStatus.icon }}
                </v-icon>
            </template>
            <span>{{ checkStatus.text }}</span>
        </v-tooltip>
    </v-row>
    <div v-else>
        <div class="grey lighten-3 caption text-center" style="border-radius: 6px; padding: 1px; max-width: 100px">
            Отсутствует
        </div>
    </div>
</template>

<script>
export default {
    name: 'LastCheckInOrderInformation',
    props: {
        order: Object,
        check: Object,
        center: Boolean
    },
    data: () => ({}),
    methods: {},
    computed: {
        lastCheck() {
            if (this.check)
                return this.check
            if (this.order.checks.length)
                return this.order.checks[this.order.checks.length - 1]
            return null
        },
        checkType() {
            if (this.lastCheck) {
                return this.$check.getCheckType(this.lastCheck)
            }
        },
        checkStatus() {
            if (this.lastCheck) {
                return this.$check.getCheckStatus(this.lastCheck)
            }
        }
    }
}
</script>

<style scoped>

</style>