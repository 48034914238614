import './models'
import '../vendor/bootstrap'
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from '../vendor/vuetify'
import '../vendor/icons'
import '../vendor/vuesas'
import 'air-vue-leaflet/dist/air-vue-leaflet.css'

Vue.prototype.$L = Vue.observable(require('air-vue-leaflet')())
require('../vendor/movingMarker')
Vue.config.productionTip = false

Vue.component('air-title', require('../components/texts/AirTitleText').default)
Vue.component('text-header', require('../components/texts/TextHeader').default)
Vue.component('text-description', require('../components/texts/AirDescriptionText').default)

Vue.component('air-card', require('../components/cards/AirCard').default)
Vue.component('air-header-text', require('../components/texts/AirHeaderText').default)
Vue.component('air-title-text', require('../components/texts/AirTitleText').default)
Vue.component('air-description-text', require('../components/texts/AirDescriptionText').default)
Vue.component('air-active-text', require('../components/texts/AirActiveText').default)
Vue.component('air-router-link', require('../components/helpers/AirRouterLink').default)
Vue.component('air-textarea', require('../components/inputs/AirTextarea').default)
Vue.component('air-divider', require('../components/helpers/AirDivider').default)

Vue.component('air-button', require('../components/buttons/AirButton').default)
Vue.component('air-change-page', require('../components/helpers/AirChangePage').default)
Vue.component('air-dialog', require('../components/helpers/AirDialog').default)
Vue.component('air-form', require('../components/helpers/AirForm').default)
Vue.component('air-icon', require('../components/helpers/AirIcon').default)
Vue.component('air-text-field', require('../components/inputs/AirTextField').default)
Vue.component('air-select', require('../components/inputs/AirSelect').default)
Vue.component('price-view', require('../components/helpers/PriceView').default)

Vue.component('just-dialog', require('../components/helpers/JustDialog').default)
Vue.component('just-card', require('../components/cards/JustCard').default)
Vue.component('just-text-field', require('../components/inputs/JustTextField').default)
Vue.component('just-select', require('../components/inputs/JustSelect').default)
Vue.component('just-date-picker-popup', require('../components/inputs/JustDatePickerPopup').default)
Vue.component('just-btn', require('../components/buttons/JustBtn').default)
Vue.component('just-description-text', require('../components/texts/AirDescriptionText').default)
Vue.component('just-data-text', require('../components/texts/JustDataText').default)
Vue.component('just-icon', require('../components/helpers/JustIcon').default)

new Vue({
    router,
    store,
    vuetify,
    render: h => h(App)
}).$mount('#app')
