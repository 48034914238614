<template>
    <air-list-template :model="$nomenclature" header="Номенклатура">
        <template v-slot:item="{item}">
            <div>{{ item.name }}</div>
            <div class="caption">Артикул: {{ item.sku }}</div>
        </template>
    </air-list-template>
</template>

<script>
import AirListTemplate from "../../../components/template/list/AirListTemplate";

export default {
    name: 'Nomenclatures',
    components: {
        AirListTemplate
    },
    data: () => ({}),
    methods: {},
}
</script>

<style scoped>

</style>