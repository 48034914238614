<template>
    <air-list-template :model="$company" header="Компании">
        <template v-slot:item="{item}">
            <div>{{ item.name }}</div>
            <div class="caption">ИНН: {{ item.inn }}</div>
        </template>
    </air-list-template>
</template>

<script>
import AirListTemplate from "../../../components/template/list/AirListTemplate";

export default {
    name: 'Companies',
    components: {
        AirListTemplate
    },
    data: () => ({}),
    methods: {},
}
</script>

<style scoped>

</style>