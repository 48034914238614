<template>
    <v-col class="pa-0">
        <v-row no-gutters align="center">
            <air-title-text class="mt-0 mb-0">
                Адреса доставки
            </air-title-text>
            <v-spacer></v-spacer>
            <just-btn @click="openDialog" >Добавить</just-btn>
        </v-row>
        <v-col class="pa-0 body-2" v-for="(el, index) in list" :key="index">
            <air-divider v-if="index" class="my-1"></air-divider>
            <v-row no-gutters align="start">
                <v-col>
                    <v-row no-gutters align="center" class="mt-0">
                        <div>{{ el.name }}</div>
                    </v-row>
                    <v-row no-gutters align="center">
                        <air-icon class="primary--text caption mr-1" icon="truck"></air-icon>
                        <div>{{ el.address || 'Отсутствует' }}</div>
                    </v-row>
                    <v-row no-gutters align="center" class="caption">
                        <div>Код: {{ el.vendor_code }}</div>
                    </v-row>
                </v-col>
                <v-col cols="auto">
                    <just-btn @click="openDialog(index)" color="transparent" height="35" width="35" class="my-2" center icon="far edit"></just-btn>
                </v-col>
            </v-row>
        </v-col>
        <div v-if="!listToShow.length" class="caption grey--text text--darken-1">
            Данные отсутствуют
        </div>
        <air-dialog v-model="dialog" v-if="item" title="Адрес доставки">
            <air-form v-model="valid">
                <just-text-field class="pt-3" :rules="[$rules.required]" label="Наименование" icon="signature"
                                v-model="item.name"></just-text-field>
                <just-text-field class="mt-3" :rules="[$rules.required]" label="Адрес" icon="signature"
                                v-model="item.address"></just-text-field>
                <just-text-field :rules="[$rules.required]" class="mt-3" label="Код" icon="code-branch"
                                v-model="item.vendor_code"></just-text-field>
            </air-form>
            <v-row no-gutters align="center" class="mt-5">
                <just-btn disabled @click="saveItem">Сохранить</just-btn>
                <v-spacer></v-spacer>
                <just-btn @click="deleteItem" v-if="currentIndex > -1" color="danger" outlined>Удалить</just-btn>
            </v-row>
        </air-dialog>
    </v-col>
</template>

<script>

export default {
    name: 'DeliveryAddressList',
    data: () => ({
        default: {
            name: null,
            email: null,
            phone: null,
            vendor_code: null,
            active: true
        },
        item: null,
        currentIndex: -1,
        dialog: false,
        valid: false
    }),
    methods: {
        openDialog(index = -1) {
            if (index === -1) {
                this.item = this.$client.copy(this.default)
            } else {
                this.item = this.$client.copy(this.list[index])
            }
            this.currentIndex = index
            this.dialog = true
        },
        deleteItem() {
            this.list.splice(this.currentIndex)
            this.dialog = false
        },
        saveItem() {
            if (this.currentIndex > -1) {
                this.list.splice(this.currentIndex, 1, this.item)
            } else {
                this.list.push(this.item)
            }
            this.dialog = false
        }
    },
    computed: {
        list() {
            return this.$client.item.delivery_addresses
        },
        listToShow() {
            return this.$client.item.delivery_addresses.filter(val => val.active)
        }
    }
}
</script>

<style scoped>

</style>