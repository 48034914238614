import { render, staticRenderFns } from "./AirTextarea.vue?vue&type=template&id=594157dd&scoped=true&"
import script from "./AirTextarea.vue?vue&type=script&lang=js&"
export * from "./AirTextarea.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "594157dd",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCol } from 'vuetify/lib/components/VGrid';
import { VTextarea } from 'vuetify/lib/components/VTextarea';
installComponents(component, {VCol,VTextarea})
