const unexpectedError = 'Произошла непредвиденная ошибка'

function getErrorMessage(error, key) {
    return key + ': ' + error
}

export function handleError(error, key = null) {
    if (typeof error === "string") {
        if (key) {
            return getErrorMessage(error, key)
        }
    }
    if (Array.isArray(error)) {
        for (let [item, index] of error) {
            if (typeof item === "string") {
                return getErrorMessage(error, key)
            } else {
                return handleError(item, index)
            }
        }
    }

    if (typeof error === "object") {
        for (let [index, value] of Object.entries(error)) {
            if (value === "string") {
                return {
                    key: key,
                    error: value
                }
            } else {
                return handleError(value, index)
            }
        }
    }
    return unexpectedError
}
