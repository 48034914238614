<template>
    <v-col class="ma-0 pa-0">
        <slot></slot>
    </v-col>
</template>

<script>
export default {
    name: 'AirForm',
    props: {
        value: Boolean
    },
    data: () => ({
        mounted: false
    }),
    methods: {},
    computed: {
        valid() {
            if (this.mounted) {
                for (const el in this.$slots.default) {
                    const valid = this.$slots.default[el]?.componentInstance?.valid
                    if (valid === false)
                        return false
                }
                return true
            }
        }
    },
    mounted() {
        this.mounted = true
    },
    watch: {
        valid(val) {
            this.$emit('input', val)
        }
    }
}
</script>

<style scoped>

</style>