<template>
    <v-col class="pa-0">
        <v-row no-gutters align="center">
            <air-title-text class="mt-3 mb-3">
                Чеки
            </air-title-text>
            <v-spacer></v-spacer>
        </v-row>
        <v-col class="pa-0 body-2 cursor-pointer" v-for="(check, index) in list" :key="index"
               @click="openDialogCheck(check)">
            <air-divider class="my-3" v-if="index"></air-divider>
            <v-row no-gutters align="start">
                <v-col>
                    <v-row no-gutters align="center" class="mt-0 mt-1 mb-1">
                        <div class="font-weight-bold">{{ $check.getCheckType(check).text }} {{ check.sum / 100 }} ₽
                        </div>
                    </v-row>
                    <v-row no-gutters align="center">
                        <div class="mb-2">{{ getDateCreatedAt(check.created_at) }}</div>
                    </v-row>
                    <last-check-in-order-information :check="check"></last-check-in-order-information>
                </v-col>
            </v-row>
        </v-col>
        <div v-if="!list" class="caption grey--text text--darken-1">
            Данные отсутствуют
        </div>
        <order-check-modal v-model="dialogCheck" :item="selectedCheck"></order-check-modal>

    </v-col>

</template>

<script>
import OrderCheckModal from "../orderCheck/OrderCheckModal";
import LastCheckInOrderInformation from "../LastCheckInOrderInformation";


export default {
    name: 'OrderChecksList',
    components: {
        OrderCheckModal,
        LastCheckInOrderInformation
    },
    data: () => ({
        dialogCheck: false,
        selectedCheck: null,
        checkStatus: null

    }),
    methods: {
        getDateCreatedAt(val) {
            return window.moment.utc(val).local().format('DD.MM.YYYY HH:mm')
        },
        openDialogCheck(item) {
            this.selectedCheck = item
            this.dialogCheck = true
        },
    },
    created() {
        // this.$nomenclature.loadList()
    },
    computed: {
        list() {
            return this.$order.item.checks
        },
        listToShow() {
            return this.$order.item.items.filter(val => val.active)
        },
    }
}
</script>

<style scoped>

</style>