import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from './views/Home.vue'
import NotFound from "../components/NotFound";
import Reports from "./views/Reports";
import OrderInformation from "./views/orderInformation/OrderInformation";
import WorkersMap from "./views/map/WorkersMap";

const router = require('air-vue-model/router')

const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home,
        meta: {
            auth: true
        },
        children: [
            ...Vue.prototype.$user.getRoutes(),
            ...Vue.prototype.$client.getRoutes(),
            ...Vue.prototype.$nomenclature.getRoutes(),
            ...Vue.prototype.$order.getRoutes(),
            ...Vue.prototype.$company.getRoutes(),
            ...Vue.prototype.$checkout.getRoutes(),
            ...Vue.prototype.$userCompany.getRoutes(),
            ...Vue.prototype.$check.getRoutes(),
            ...Vue.prototype.$userDevice.getRoutes(),
            {
                name: 'reports',
                path: 'reports',
                component: Reports
            },
            {
                name: 'workersMap',
                path: 'map',
                component: WorkersMap
            }
        ]
    },
    {
        path: '*',
        component: NotFound
    },
    {
        path: '/order_information',
        component: OrderInformation
    }
]
export default router(Vue, VueRouter, routes)
