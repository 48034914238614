<template>
    <v-navigation-drawer permanent clipped floating app class="gradient-background" expand-on-hover>
        <v-col class="pa-0" style="margin-top: 17px">
            <v-list nav dense
            >
                <v-list-item-group
                    color="primary darken-1">
                    <v-list-item :to="{name: el.to}"
                                 v-for="(el, index) in items" :key="index"
                    >
                        <v-list-item-icon>
                            <v-icon size="17" color="black">{{ el.icon }}</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>{{ el.name }}</v-list-item-title>
                    </v-list-item>
                </v-list-item-group>
            </v-list>
        </v-col>
    </v-navigation-drawer>
</template>

<script>
export default {
    name: 'Drawer',
    props: {},
    data: () => ({
        items: [
            {to: 'companyList', name: 'Компании', icon: 'mdi-home-city'},
            {to: 'clientList', name: 'Контрагенты', icon: 'mdi-domain'},
            {to: 'nomenclatureList', name: 'Номенклатура', icon: 'mdi-bookmark'},
            {to: 'orderList', name: 'Заказы', icon: 'mdi-file-document-multiple'},
            {to: 'checkoutList', name: 'Кассы', icon: 'mdi-arrange-send-backward'},
            {to: 'reports', name: 'Отчеты', icon: 'mdi-chart-box'},
            {to: 'workersMap', name: 'Карта', icon: 'mdi-map-clock'},
            {to: 'userDeviceList', name: 'Устройства', icon: 'mdi-cellphone-text'},
        ]
    }),
    methods: {}
}
</script>

<style scoped>
.gradient-background {
    background: rgb(138, 117, 205);
    background: linear-gradient(0deg, rgba(138, 117, 205, 1) 22%, rgba(183, 164, 245, 1) 88%);
}
</style>