<template>
    <div v-if="lastPage">
        <v-row no-gutters align="center">
            <div class="mt-1 mr-4 ml-5 body-2 grey--text text--darken-2">
                {{ value }}
                <span class="caption"> / </span>
                {{ lastPage }}
            </div>
            <v-btn
                :disabled="value <= firstPage" outlined icon @click="changeSlide(-1)">
                <v-icon>mdi-chevron-left</v-icon>
            </v-btn>
            <v-btn
                v-ripple="{ class: `primary--text text--lighten-5` }"
                :disabled="value >= lastPage" outlined icon class="ml-3" @click="changeSlide(1)">
                <v-icon>mdi-chevron-right</v-icon>
            </v-btn>
        </v-row>
    </div>
</template>

<script>
export default {
    name: 'ChangePage',
    props: {
        firstPage: {
            type: Number,
            default: 1
        },
        lastPage: {
            type: Number,
            default: 2
        },
        value: {
            type: Number,
            default: 1
        }
    },
    data: () => ({}),
    methods: {
        changeSlide(value) {
            this.$emit('input', this.value + value)
        },
    }
}
</script>

<style scoped>

</style>