<template>
    <air-list-template hide-create :model="$userDevice" header="Устройства" :to-item-name="null">
        <template v-slot:item="{item}">
            <v-col class="pa-0">
                <v-row no-gutters align="start">
                    <v-icon color="grey" size="20" class="mr-2">
                        {{ item.system !== 'android' ? 'mdi-apple' : 'mdi-android' }}
                    </v-icon>
                    <div>
                        <div>{{ getBrandView(item.brand) }} {{ item.model }}
                            <span class="caption grey--text" v-if="item.system_version">v.{{
                                    item.system_version
                                }}</span>
                        </div>
                        <div>
                            <span>{{ getLastUserActivity(item) }}</span>
                            <v-menu content-class="air-shadow" min-width="300" offset-y
                                    v-if="item.activities.length > 1">
                                <template v-slot:activator="{ on, attrs }">
                                    <span v-on="on"
                                          class="cursor-pointer ml-2 primary--text caption text-decoration-underline">Дополнительно {{
                                            item.activities.length - 1
                                        }} за 24 часа</span>
                                </template>
                                <v-card flat class="pa-3 body-2">
                                    <v-col class="pa-0" v-for="(el, index) in item.activities.slice(1, 1000)">
                                        <v-divider v-if="index" class="my-1"></v-divider>
                                        <v-row no-gutters>
                                            <div>{{ el.user.first_name }} {{ el.user.last_name }}</div>
                                            <v-spacer></v-spacer>
                                            <div class="pl-3">{{ getLastDateView(el.updated_at, false) }}</div>
                                        </v-row>
                                    </v-col>
                                </v-card>
                            </v-menu>

                        </div>
                        <div class="caption grey--text text--darken-2">{{ getLastDateView(item.updated_at) }}</div>
                    </div>
                    <v-spacer></v-spacer>
                    <v-row no-gutters align="end" class="flex-column caption grey--text text--darken-2">
                        <div class="text-end">
                            Версия {{ item.app_version }} / {{ item.app_build_version }}
                            <v-icon
                                :color="getLocationColor(item.location_status)"
                                size="18" class="ml-1">mdi-map-marker
                            </v-icon>
                        </div>
                        <div class="text-end">
                            <div v-if="item.battery" class="text-end">Заряд {{ item.battery }}%
                                <v-icon color="grey" size="18" :color="getBatteryColor(item.battery)">
                                    {{ getBatteryIcon(item.battery) }}
                                </v-icon>
                            </div>
                            <div>{{ item.uuid }}</div>
                        </div>
                    </v-row>
                    <div class="caption grey--text text--darken-2">


                    </div>
                </v-row>
            </v-col>
        </template>
    </air-list-template>
</template>

<script>
import AirListTemplate from "../../../components/template/list/AirListTemplate";
import getLastDateView from "../../../mixins/getLastDateView";

export default {
    name: 'UserDevices',
    components: {
        AirListTemplate
    },
    mixins: [
        getLastDateView
    ],
    data: () => ({}),
    methods: {
        getLastUserActivity(item) {
            if (item.activities.length) {
                return `${item.activities[0].user.first_name} ${item.activities[0].user.last_name}`
            } else if (item.user) {
                return `${item.user.first_name} ${item.user.last_name}`
            }
            return 'По устройству отсутствует активность'
        },
        getBatteryColor(value) {
            if (value >= 60) return 'green'
            if (value >= 20) return 'yellow darken-2'
            return 'red'
        },
        getBatteryIcon(value) {
            if (value >= 60) return 'mdi-battery-high'
            if (value >= 20) return 'mdi-battery-medium'
            return 'mdi-battery-low'
        },
        getLocationColor(location) {
            if (location === 'enabled') return 'green'
            if (location === 'disabled') return 'grey'
            return 'red'
        },
        getBrandView(brand) {
            return brand ? brand.charAt(0).toUpperCase() + brand.slice(1) : 'Отсутствует'
        }
    }
}
</script>

<style scoped>

</style>