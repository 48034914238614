<template>
    <v-col class="pa-0">
        <v-row no-gutters align="center" class="mb-2">
            <air-title-text class="mt-0 mb-0">
                Позиции заказа
            </air-title-text>
            <v-spacer></v-spacer>
            <just-btn v-if="!read" :disabled="item.checks.length > 0" @click="openDialog">Добавить</just-btn>
        </v-row>
        <v-col class="pa-0 body-2 cursor-pointer" v-for="(el, index) in item.items" :key="index" @click="openDialog(index)">
            <air-divider v-if="index" class="my-3"></air-divider>
            <v-row no-gutters align="start">
                <v-col>
                    <v-col class="pa-0">
                        <v-row no-gutters align="center">
                            <div class="font-weight-bold mb-2">{{ el.nomenclature.sku }}
                                <span class="secondary--text font-weight-regular">
                                    {{ getFiscalizedData(getFiscalizedCalc, el) }}
                                </span>
                            </div>
                            <v-spacer></v-spacer>
                            <div class="font-weight-bold mb-2" style="white-space: nowrap;"> {{ el.sum / 100 }} ₽</div>
                            <div class="secondary--text mb-2 text-no-wrap ml-2">
                                {{ el.quantity }} {{ el.nomenclature.measurement_unit }}
                            </div>
                        </v-row>
                        <v-row no-gutters align="center" class="mt-0">
                            <div class="secondary--text">{{ el.nomenclature.name }}</div>
                            <v-spacer></v-spacer>
                            <div class="secondary--text" style="white-space: nowrap;">{{ el.price / 100 }} ₽
                            </div>
                            <div class="secondary--text ml-2"> 1 {{ el.nomenclature.measurement_unit }}</div>
                        </v-row>
                        <v-row no-gutters align="center" class="mt-1">
                            <div class="secondary--text"> Доступно для продажи:
                                {{ getFiscalizedCalc.find(val => val.nomenclature === el.nomenclature.id).available }}
                            </div>
                        </v-row>
                    </v-col>
                </v-col>
            </v-row>
        </v-col>

        <air-dialog v-model="dialog" v-if="value" :checks="item.checks.length" title="Позиция заказа">

            <air-form v-model="valid" v-if="!read">
                <just-select class="mt-2" item-text="name" item-value="id" v-model="value.nomenclature"
                             :items="$nomenclature.list"
                             label="Наименование"></just-select>
                <just-select class="mt-3" label="Ставка НДС" v-model="value.vat" :items="$nomenclature.vats"
                             item-text="name"
                             item-value="value"></just-select>
                <just-select class="mt-3" label="Предмет расчёта" v-model="value.payment_object"
                             :items="$order.payment_object"
                             item-text="name"
                             item-value="value"></just-select>
                <just-select class="mt-3" label="Тип оплаты" v-model="value.payment_method"
                             :items="$order.payment_method"
                             item-text="name"
                             item-value="value"></just-select>

                <just-text-field class="mt-3" :rules="[$rules.required]" label="Количество" icon="signature"
                                 v-model="value.quantity"></just-text-field>
                <just-text-field :rules="[$rules.required]" class="mt-3" label="Цена" icon="at"
                                 v-model="value.price"></just-text-field>
            </air-form>
            <air-form v-else>
                <just-data-text label="Наименование" :text="value.nomenclature.name"></just-data-text>
                <just-data-text label="Ставка НДС" :text="$nomenclature.vats.find(el => el.value === value.vat).name"></just-data-text>
                <just-data-text label="Предмет расчета" :text="$order.payment_object.find(el => el.value === value.payment_object).name"></just-data-text>
                <just-data-text label="Тип оплаты" :text="$order.payment_method.find(el => el.value === value.payment_method).name"></just-data-text>
                <just-data-text label="Количество" :text="value.quantity"></just-data-text>
                <just-data-text label="Цена" :text="value.price"></just-data-text>
            </air-form>
            <v-row v-if="!read" no-gutters align="center" class="mt-5">
                <!--                <just-btn :disabled="!valid" @click="saveItem">Сохранить</just-btn>-->
                <just-btn disabled @click="saveItem">Сохранить</just-btn>
                <v-spacer></v-spacer>
                <just-btn :disabled="item.checks.length > 0 " @click="deleteItem" v-if="currentIndex > -1"
                          color="danger" outlined>Удалить
                </just-btn>
            </v-row>
        </air-dialog>
    </v-col>
</template>

<script>
export default {
    name: 'OrderItemsList',
    props: {
        item: Object,
        read: {
            type: Boolean,
            default: false
        }
    },
    data: () => ({
        default: {
            price: null,
            quantity: null,
            nomenclature: null,
            payment_object: null,
            payment_method: null,
            vat: 'vat20',
        },
        value: null,
        currentIndex: -1,
        dialog: false,
        valid: false,
        fiscal_calc: [],
    }),
    methods: {
        openDialog(index = -1) {
            if (index === -1) {
                this.value = this.$order.copy(this.default)
            } else {
                this.value = this.$order.copy(this.list[index])
            }
            this.currentIndex = index
            this.dialog = true
        },
        deleteItem() {
            this.list.splice(this.currentIndex)
            this.dialog = false
        },
        saveItem() {
            if (this.currentIndex > -1) {
                this.list.splice(this.currentIndex, 1, this.item)
            } else {
                this.list.push(this.item)
            }
            this.dialog = false
        },
        getFiscalizedData(data, el) {
            let fiscalized = data.find(val => val.nomenclature === el.nomenclature.id).fiscalized
            let all = data.find(val => val.nomenclature === el.nomenclature.id).start_amount
            return fiscalized + ' / ' + all
        }
    },
    created() {
        if (!this.read) {
            this.$nomenclature.loadList()
        }
    },
    computed: {
        list() {
            return this.$order.item.items
        },

        listToShow() {
            return this.$order.item.items.filter(val => val.active)
        },
        getFiscalizedCalc(list) {
            let checks = this.$order.item.checks
            let fiscal_calc = []
            let statuses = ['done', 'wait']
            list.list.forEach(order_item => {
                fiscal_calc.push({
                    'nomenclature': order_item.nomenclature.id,
                    'start_amount': order_item.quantity,
                    'fiscalized': 0,
                    'available': order_item.quantity
                })
            })
            fiscal_calc.forEach(item => {
                checks.forEach(check => {
                    check.items.forEach(check_item => {
                        if (check_item.nomenclature.id === item.nomenclature) {
                            if (check.type === 1 && statuses.includes(check.status)) {
                                item.fiscalized += check_item.quantity
                                item.available = item.start_amount - item.fiscalized
                            } else if (check.type !== 1 && statuses.includes(check.status)) {
                                item.fiscalized -= check_item.quantity
                                item.available = item.start_amount + item.fiscalized
                            }
                        }
                    })
                })
            })
            return fiscal_calc
        },

    }
}
</script>

<style scoped>

</style>