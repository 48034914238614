<template>
    <div :style="`font-size: ${size}px`" class="transition-fast-in-fast-out">
        <font-awesome-icon class="transition-fast-in-fast-out"
                           v-if="currentIcon" :icon="currentIcon"/>
    </div>
</template>

<script>
export default {
    name: 'JustIcon',
    props: {
        icon: String,
        size: [String, Number]
    },
    data: () => ({}),
    methods: {},
    created() {

    },
    computed: {
        currentIcon() {
            if (!this.icon)
                return null
            const splitValues = this.icon.split(' ')
            if (splitValues.length > 1)
                return splitValues
            return this.icon
        }
    }
}
</script>

<style scoped>

</style>