<template>
    <v-row class="no-gutters fill-height">
        <v-col cols="3" class="pa-3 white">
            <div style="max-height: 87vh; overflow-y: auto">
                <just-select @change="changeCompany" label="Компания" v-model="company" :items="$company.list"
                             item-text="name"
                             item-value="inn"></just-select>
                <div class="caption mb-1 mt-3 secondary--text" style="line-height: 0.75rem;">Дата</div>
                <date-picker @change="loadChecks" flat solo :outlined="false" background-color="#f5f5f5"
                             v-model="date"></date-picker>
                <vs-checkbox @change="drawChecks" v-model="sells" class="mt-3">Продажа - {{ checksCount.sells }}
                </vs-checkbox>
                <vs-checkbox @change="drawChecks" v-model="returns">Возврат - {{ checksCount.returns }}</vs-checkbox>
                <div class="font-weight-bold mb-3 mt-3">Курьеры</div>
                <v-col class="pa-0" v-for="(el, index) in $userCompany.list">
                    <v-divider class="my-2" v-if="index"></v-divider>
                    <v-row @click="selectWorker(el.id)" no-gutters align="start" class="cursor-pointer">
                        <v-icon class="mr-2 mt-1" size="15" :color="el.color">mdi-circle</v-icon>
                        <v-col>
                            <div
                                :class="{'font-weight-bold primary--text': el.id === selectedWorker}"
                            >{{ el.user.first_name }} {{ el.user.last_name }}
                            </div>
                            <div class="caption grey--text text--darken-1">{{ el.user.email }}</div>
                            <div v-if="!el.user.location">
                                <div class="caption">Данные о местоположении отсутствуют</div>
                            </div>
                            <div v-else class="caption">
                                {{ getLastDateView(el.user.location.updated_at) }}
                            </div>
                        </v-col>
                    </v-row>
                </v-col>
            </div>
        </v-col>
        <v-col cols="9 fill-height pa-3">
            <div class="rounded-lg" id="workers-map" style="width: 100%; height: 100%; z-index: 1"></div>
        </v-col>
        <order-check-modal :item="selectedCheck" v-model="checkDialog"></order-check-modal>
    </v-row>
</template>

<script>

import DatePicker from "../../../components/inputs/DatePicker";
import OrderCheckModal from "../orders/orderCheck/OrderCheckModal";
import getLastDateView from "../../../mixins/getLastDateView";

export default {
    name: 'WorkersMap',
    data: () => ({
        checkDialog: false,
        selectedCheck: null,
        workersLayer: null,
        checksLayer: null,
        companyWebsocket: null,
        company: null,
        currentChecks: [],
        selectedWorker: null,
        sells: true,
        returns: true,
        date: null
    }),
    components: {
        DatePicker,
        OrderCheckModal
    },
    mixins: [
        getLastDateView
    ],
    methods: {
        createChecksCollection(values) {
            const currentValues = values.map(val => {
                return {
                    id: val.uuid,
                    coords: val.coords,
                    status: val.status,
                    type: val.type,
                    worker: val.worker
                }
            })
            return this.$L.pointCollection(currentValues, val => {
                const statusData = this.$check.getCheckStatus(val)
                return {
                    color: statusData.color,
                    icon: statusData.icon,
                    worker: val.worker
                }
            })
        },
        selectWorker(id) {
            this.selectedWorker = this.selectedWorker === id ? null : id
            this.drawChecks()
        },
        drawChecks() {
            const layer = this.createChecksLayer(this.createChecksCollection(this.checksToDraw))
            this.$L.drawLayer(this, 'checksLayer', layer, true)
        },
        loadChecks() {
            const startDate = moment(`${this.date} 00:00:00`).utc().format('YYYY-MM-DD HH:mm:ss')
            const endDate = moment(`${this.date} 23:59:59`).utc().format('YYYY-MM-DD HH:mm:ss')
            this.$check.sendGet('map', {
                company: this.company,
                start_date: startDate,
                end_date: endDate
            }).then(res => {
                this.currentChecks = res.results
                this.drawChecks()
            })
        },
        changeCompany() {
            this.loadUsers()
            this.loadChecks()
            this.connectCompanyWebsocket()
        },
        loadUsers() {
            this.$userCompany.loadList({page_size: 'all', active: true, company: this.company, role: 2}).then(res => {
                if (!res.length)
                    return
                this.sortList()
                const currentUsers = res.filter(val => !!val.user.location).map(val => {
                    return {
                        id: val.id,
                        coords: val.user.location.coords,
                        user: val.user.id,
                        companyUser: val.id,
                        color: val.color
                    }
                })
                const pointCollection = this.$L.pointCollection(currentUsers, val => {
                    return {
                        color: val.color,
                        companyUser: val.companyUser,
                        user: val.user
                    }
                })
                this.$L.drawLayer(this, 'workersLayer', this.createPointsLayer(pointCollection))
                this.$L.map.flyToBounds(this.workersLayer.getBounds(), {
                    duration: 1.5,
                    maxZoom: 13
                })
            })
        },
        connectCompanyWebsocket() {
            const protocol = window.location.protocol === 'https:' ? 'wss' : 'ws'
            const token = localStorage.getItem('access')
            this.companyWebsocket = new WebSocket(`${protocol}://${window.location.host}/ws/companies/${this.company}/?token=${token}`)
            this.companyWebsocket.onopen = () => {

            }
            this.companyWebsocket.onclose = e => {
                this.companyWebsocket = null
                setTimeout(() => {
                    this.connectCompanyWebsocket()
                }, 5000)
            }
            this.companyWebsocket.onmessage = e => {
                const data = JSON.parse(e.data)
                if (data.type === 'user_location_updated') {
                    const companyUser = this.$userCompany.list.find(val => val.user.id === data.data.user && !!val.user.location)
                    if (companyUser) {
                        companyUser.user.location.updated_at = data.data.updated_at
                        companyUser.user.location.coords = data.data.coords
                        this.sortList()
                    }
                    if (this.workersLayer) {
                        this.workersLayer.eachLayer(val => {
                            if (val.feature.properties.user === data.data.user) {
                                const coords = data.data.coords
                                const newLatLng = new L.LatLng(coords[1], coords[0]);
                                val.moveTo(newLatLng, 3000)
                            }
                        })
                    }
                } else if (data.type === 'order_check_map_updated') {
                    const check = data.data
                    const currentTime = moment(check.created_at).local().format('YYYY-MM-DD')
                    if (this.date !== currentTime)
                        return
                    let found = false
                    this.checksLayer.eachLayer(val => {
                        if (val.feature.id === check.uuid) {
                            const statusData = this.$check.getCheckStatus(check)
                            val.setIcon(this.getCheckIcon(statusData.color, check.worker.color, statusData.icon))
                        }
                    })
                    if (!found) {
                        const newLayer = this.createChecksLayer(this.createChecksCollection([check]))
                        newLayer.addTo(this.checksLayer)
                    }
                    const index = this.currentChecks.findIndex(val => val.uuid === check.uuid)
                    if (index === -1) this.currentChecks.push(check)
                    else this.currentChecks.splice(index, 1, check)
                }
            }
        },
        createChecksLayer(collection) {
            return L.geoJson(collection, {
                onEachFeature: (feature, layer) => {
                    layer.on('click', () => {
                        this.$check.loadItem(feature.id).then(res => {
                            this.selectedCheck = res
                            this.checkDialog = true
                        })
                    })
                },
                pointToLayer: (feature, latlng) => {
                    const color = feature.properties.color
                    const bgColor = feature.properties.worker.color
                    const icon = feature.properties.icon
                    return L.marker(latlng, {
                        icon: this.getCheckIcon(color, bgColor, icon)
                    })
                },
            })
        },
        getCheckIcon(color, workerColor, icon) {
            return new L.divIcon({
                className: 'custom-div-icon',
                html: `<div style="background-color: ${workerColor}" class="marker-pin"></div>
                                <i class="mdi ${icon} ${color}--text"></i>`,
                iconSize: [30, 42], iconAnchor: [15, 42]
            })
        },
        createPointsLayer(collection, onClick = null) {
            return L.geoJson(collection, {
                onEachFeature: (feature, layer) => {
                    if (onClick)
                        layer.on('click', () => onClick(feature, layer))
                },
                pointToLayer: (feature, latlng) => {
                    const color = feature.properties.color
                    const drawIcon = new L.divIcon({
                        className: 'custom-div-icon',
                        html: `<div style="background-color: ${color}" class="marker-pin"></div>
                                <i class="mdi ${'mdi-account'}" style="color: ${color}"></i>`,
                        iconSize: [30, 42], iconAnchor: [15, 42]
                    })
                    return L.Marker.movingMarker([latlng],
                        [2000], {
                            icon: drawIcon
                        })
                },
            })
        },
        sortList() {
            this.$userCompany.list.sort(function (a, b) {
                const firstLocation = a.user.location
                const secondLocation = b.user.location
                if (!firstLocation && !secondLocation) return 0
                if (!secondLocation) return -1
                if (!firstLocation) return 1
                const firstTime = firstLocation.updated_at
                const secondTime = secondLocation.updated_at
                if (firstTime > secondTime) return -1
                if (firstTime < secondTime) return 1
                return 0
            })
        }
    },
    computed: {
        checksCount() {
            let sells = 0
            let returns = 0
            this.currentChecks.forEach(val => {
                if (val.type === 1) sells += 1
                else returns += 1
            })
            return {sells, returns}
        },
        checksToDraw() {
            return this.currentChecks.filter(val => {
                if (this.selectedWorker && this.selectedWorker !== val.worker.id)
                    return false
                if (!this.sells && val.type === 1) return false
                return !(!this.returns && [2, 3].indexOf(val.type) > -1)
            })
        }
    },
    mounted() {
        this.date = moment.utc().local().format('YYYY-MM-DD')
        this.company = this.$company.list[0].inn
        this.$L.initMap('workers-map', [57, 73], 3)
        L.control.mousePosition().addTo(this.$L.map);
        this.loadUsers()
        this.loadChecks()
        this.connectCompanyWebsocket()
    }
}
</script>

<style scoped>

</style>