<template>
    <div :class="{'mb-3': !hideDefaults}">
        <div v-if="label" class="secondary--text mb-1" style="font-size: 14px; line-height: 14px"
             @click="$emit('click')">
            {{ label }}
        </div>
        <div v-if="text">
            {{ text }}
        </div>
        <div v-if="date">
            {{ getDate(date) }}
        </div>
        <div v-if="dateTime">
            {{ getDateTime(dateTime) }}
        </div>
    </div>
</template>

<script>
export default {
    name: 'JustDataText',
    props: {
        label: String,
        text: [String, Number],
        date: String,
        dateTime: String,
        hideDefaults: Boolean
    },
    data: () => ({}),
    methods: {
        getDate(date) {
            return moment.utc(date).local().format('DD.MM.YYYY')
        },
        getDateTime(dateTime) {
            return moment.utc(dateTime).local().format('DD.MM.YYYY HH:mm')
        },
    }
}
</script>

<style scoped>

</style>