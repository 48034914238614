<template>
    <v-hover v-slot="{ hover }">
        <div
            @click="$emit('click')"
            :class="{
                    'primary--text': hover,
                    'grey--text': !hover && !active,
                    'black--text': !hover && active
                }"
            class="transition-fast-in-fast-out pointer font-weight-medium body-2">
            <slot></slot>
        </div>
    </v-hover>
</template>

<script>
export default {
    name: 'AirActiveText',
    props: {
        active: Boolean,
        to: Object,
    },
    data: () => ({}),
    methods: {}
}
</script>

<style scoped>

</style>