<template>
    <v-app>
        <router-view></router-view>
    </v-app>
</template>

<script>

export default {
    name: 'App',

    components: {},
    methods: {},

    data: () => ({
        //
    }),
    created() {

    }
};
</script>

<style>
.v-input__slot {
    padding: 0 15px !important;
}
.v-sheet.v-list:not(.v-sheet--outlined) {
box-shadow: none !important;
}
.v-menu__content {
    border-radius: 0 0 6px 6px;
}
.v-list.v-select-list {
    background-color: #f5f5f5;
}
.theme--light.v-input--is-disabled input, .theme--light.v-input--is-disabled textarea {
    color: rgba(0, 0, 0, 0.8);
}
.theme--light.v-input--is-disabled {
    color: rgba(0, 0, 0, 0.8) !important;
}
.v-select__selection.v-select__selection--comma.v-select__selection--disabled {
    color: rgba(0, 0, 0, 0.8) !important;
    opacity: 0.9;
}
[disabled] {
    opacity: 0.9 !important;
}

.air-shadow {
    box-shadow: 0 3px 8px 0 rgba(34, 60, 80, 0.2) !important;
}
</style>