<template>
    <v-col class="pa-0" style="position: relative">
        <v-progress-linear :active="model.loadings.list || model.loadings.item"
                           indeterminate style="position: absolute; top: -12px"
                           color="primary"
        ></v-progress-linear>
        <v-row no-gutters align="center" class="flex-nowrap px-5 my-3">
            <v-col>
                <air-header-text v-if="!sublist">{{ _header }}</air-header-text>
                <air-title-text v-else>{{ _header }}</air-title-text>
            </v-col>
            <v-col cols="auto">
                <v-row no-gutters align="center">
                    <slot name="actions"></slot>
                    <just-btn v-if="!hideCreate" :to="_to()">Создать</just-btn>
                </v-row>
            </v-col>
        </v-row>
        <v-col class="pa-0 mb-5" v-if="$slots.filters">
            <v-row no-gutters align="center">
                <slot name="filters">
                </slot>
            </v-row>
            <air-divider></air-divider>
        </v-col>
        <v-col class="mt-5 pa-0" v-if="$slots.items">
            <slot name="items"></slot>
        </v-col>
        <v-col class="pa-0 body-2 px-5" v-else-if="_list.length">
            <air-card class="px-0 py-3">
                <v-col class="pa-0" v-for="(item, index) in _list" :key="index">
                    <air-divider v-if="index"></air-divider>
                    <router-link style="width: 100%" :is="_to(item) ? 'router-link' : 'div'" :to="_to(item)"
                                 class="black--text">
                        <v-col class="py-2 px-5 selected-item">
                            <slot name="item" v-bind:item="item"></slot>
                        </v-col>
                    </router-link>
                </v-col>
            </air-card>
        </v-col>
        <v-row no-gutters align="center" justify="end"
               v-if="model && model.pagination.page_size !== 'all' && model.pagination.last_page >1"
               class="px-5 mt-5 mb-3">
            <air-change-page :last-page="model.pagination.last_page"
                             v-model="model.pagination.page"></air-change-page>
        </v-row>
    </v-col>
</template>

<script>

import AirHeaderText from "../../texts/AirHeaderText";
import AirTitleText from "../../texts/AirTitleText";

export default {
    name: 'AirListTemplate',
    props: {
        header: String,
        model: Object,
        items: Array,
        sublist: Boolean,
        showTopPagination: Boolean,
        toItemName: {
            type: String,
            default: 'item'
        },
        hideCreate: Boolean
    },
    components: {
        AirHeaderText,
        AirTitleText
    },
    computed: {
        _header() {
            if (this.header)
                return this.header
            if (this.model)
                return this.model.texts.list
        },
        _list() {
            if (this.items)
                return this.items
            if (this.model)
                return this.model.list
            return []
        },
    },
    methods: {
        _to(value = null) {
            if (this.toItemName && this.model) {
                const routeName = `${this.model.name}${this.toItemName.charAt(0).toUpperCase() + this.toItemName.slice(1)}`
                return {
                    name: routeName, params: {[this.model.routeParam]: value ? value[this.model.pk] : 'new'}
                }
            } else
                return null
        }
    },
    created() {
        if (this.model) {
            this.model.loadList()
        }
    }
}
</script>

<style scoped>
.selected-item {
    transition: 0.3s all;
}

.selected-item:hover {
    background: rgba(139, 119, 207, 0.05);
}
</style>
