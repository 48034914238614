import { render, staticRenderFns } from "./AirButton.vue?vue&type=template&id=15ae04ab&scoped=true&"
import script from "./AirButton.vue?vue&type=script&lang=js&"
export * from "./AirButton.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "15ae04ab",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VRow})
