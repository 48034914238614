<template>
    <order-list-template :model="$order" header="Заказы">
        <template v-slot:item="{item}">
            <v-col class="pa-0">
                <div class="font-weight-bold mb-3">Заказ {{ item.number }} {{ getDate(item.created_at) }}</div>
                <div class="mb-3">
                    <span style="font-size: 14px" class="secondary--text">Получатель</span> <br>
                    <span>{{ item.client.name }}</span>
                </div>
                <div class="mb-3">
                    <span style="font-size: 14px" class="secondary--text">Адрес доставки</span> <br>
                    <span class="text-decoration-underline">{{ item.delivery_address.address }}</span>
                </div>
                <div class="mb-0">
                    <span style="font-size: 14px" class="secondary--text">Дата доставки</span> <br>
                    <span class="primary--text">{{ getDate(item.delivery_date) }}</span>
                </div>
            </v-col>
        </template>
    </order-list-template>
</template>

<script>
import AirListTemplate from "../../../components/template/list/AirListTemplate";
import OrderListTemplate from "./OrderListTemplate";

export default {
    name: 'Orders',
    components: {
        OrderListTemplate,
        AirListTemplate
    },
    data: () => ({}),
    methods: {
        parseDate(date) {
            return moment(date).local().format('DD-MM-YYYY HH:mm:ss')
        },
        parseWithoutSeconds(date) {
            return moment(date).local().format('DD-MM-YYYY')
        },
        getDate(date) {
            return moment(date).format('DD MMM YYYY')
        },

    },
}
</script>

<style scoped>

</style>