<template>
  <air-dialog
    :value="value"
    @close="$emit('input', false)"
    @click:outside="$emit('input', false)"
  >
    <div v-if="item" style="max-width: 400px">
      <air-form v-model="valid">
        <v-row
          v-if="
            (item.user && typeof item.user !== 'number' && !item.user.id) ||
            !item.user ||
            typeof item.user === 'number'
          "
          class="mt-3 mb-0"
          justify="center"
        >
          <v-col align="center" class="pa-0">
            <just-btn
              :color="selectCreatedUser ? 'gray' : 'primary'"
              @click="selectCreatedUser = !selectCreatedUser"
              >Создать пользователя
            </just-btn>
          </v-col>
          <v-col align="center" class="pa-0">
            <just-btn
              :color="!selectCreatedUser ? 'gray' : 'primary'"
              @click="selectCreatedUser = !selectCreatedUser"
              >Выбрать пользователя
            </just-btn>
          </v-col>
        </v-row>
        <template v-if="!selectCreatedUser">
          <v-row>
            <v-col>
              <just-text-field
                v-model="item.user.first_name"
                :rules="[$rules.required]"
                label="Имя"
              ></just-text-field>
            </v-col>
            <v-col>
              <just-text-field
                v-model="item.user.last_name"
                :rules="[$rules.required]"
                label="Фамилия"
              ></just-text-field>
            </v-col>
          </v-row>
          <just-text-field
            v-model="item.user.email"
            :disabled="!!item.id"
            :rules="[$rules.required, $rules.email]"
            class="mt-3"
            label="Email"
          ></just-text-field>
          <just-text-field
            v-model="item.user.password"
            :rules="!item.id ? [$rules.required, $rules.password] : []"
            class="mt-3"
            label="Пароль"
            type="password"
          ></just-text-field>
        </template>
        <v-autocomplete
          v-else
          v-model="item.user"
          :item-text="getUserName"
          :loading="$user.loading"
          :items="currentSearchUsers"
          :search-input.sync="$user.filters.search"
          append-icon="fa-angle-down"
          background-color="#f5f5f5"
          class="mt-5"
          clearable
          dense
          filled
          flat
          hide-details
          item-value="id"
          label="Пользователь"
          no-data-text="Данные отсутствуют"
          no-filter
          solo
          style="border-radius: 6px"
          @change="clearUserTimeout"
          @update:search-input="searchUserCompany"
        />
        <!--        <v-autocomplete class="mt-5"-->
        <!--                        @change="clearWorkerTimeout"-->
        <!--                        no-filter-->
        <!--                        no-data-text="Данные отсутствуют"-->
        <!--                        v-model="$order.filters.worker"-->
        <!--                        :items="$userCompany.searchList"-->
        <!--                        :loading="$userCompany.loadings.list"-->
        <!--                        :search-input.sync="$userCompany.filters.search"-->
        <!--                        @update:search-input="searchUserCompany"-->
        <!--                        :item-text="userCompanyText"-->
        <!--                        outlined dense hide-details-->
        <!--                        item-value="id" clearable-->
        <!--                        label="Работник"-->
        <!--        >-->
        <!--        </v-autocomplete>-->
        <v-row class="mt-1">
          <v-col>
            <just-select
              v-model="item.role"
              :items="$userCompany.roles"
              item-text="name"
              item-value="value"
              label="Роль"
            ></just-select>
          </v-col>
          <v-col v-if="item.role === 2">
            <just-text-field
              v-model="item.code"
              label="Код работника"
              type="number"
            ></just-text-field>
          </v-col>
        </v-row>
        <v-color-picker
          v-if="item.role === 2"
          v-model="item.color"
          class="mt-5 mx-0"
          dot-size="25"
          hide-canvas
          hide-details
          width="100%"
        ></v-color-picker>
        <v-checkbox
          v-model="item.active"
          class="mb-0 mt-3"
          hide-details
          label="Активен"
          style="margin-left: -15px"
        ></v-checkbox>
      </air-form>
      <v-row align="center" class="mt-5" no-gutters>
        <just-btn
          v-if="itemUserId !== $auth.user.id"
          :disabled="!valid"
          :loading="loading"
          @click="saveItem()"
          >Сохранить
        </just-btn>
      </v-row>
    </div>
  </air-dialog>
</template>

<script>
export default {
  name: "UserCompanyModal",
  props: {
    value: Boolean,
    item: Object,
  },
  data: () => ({
    loading: false,
    valid: false,
    selectCreatedUser: false,
    availableUsers: [],
    userTimeout: null,
    currentSearchUsers: []
  }),
  methods: {
    async saveItem() {
      this.loading = true;
      if (!this.item.code || this.item.role === 1) this.item.code = null;
      const { promise, created } = this.$userCompany.updateOrCreate(this.item);
      promise
        .then((res) => {
          const userIndex = this.$userCompany.list.findIndex(
            (e) => e.id === this.item.id
          );
          if (userIndex > -1) this.$userCompany.list.splice(userIndex, 1, res);
          else {
            this.$userCompany.list.push(res);
          }
          this.$snackbar.success("Пользователь обновлен");
          this.$emit("input", false);
          this.$emit("change", false);
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
          this.$snackbar.fail("Не удалось обновить");
        });
    },
    getUserName(item) {
      return `${item.first_name} ${item.last_name}`;
    },
    searchUserCompany() {
      clearTimeout(this.userTimeout);
      this.userTimeout = setTimeout(() => {
        this.$user.loadList({}, { setToModel: false }).then((res) => {
          this.currentSearchUsers = res.results;
        });
      }, 300);
    },
    clearUserTimeout() {
      setTimeout(() => {
        clearTimeout(this.userTimeout);
      }, 200);
    },
  },
  watch: {
    selectCreatedUser: function () {
      this.item.user = {
        first_name: null,
        last_name: null,
        password: null
      }
    },
    value: function () {
      if (this.value) this.selectCreatedUser = false;
      this.searchUserCompany()
    },
  },
  computed: {
    itemUserId: function () {
      if (!this.item.user) return null;
      else if (typeof this.item.user === "number") return this.item.user;
      else return this.item.user.id;
    },
  },
};
</script>

<style>
.v-color-picker__controls {
  padding: 0 !important;
}
</style>
