<template>
    <router-link :to="to">
        <slot></slot>
    </router-link>
</template>

<script>
export default {
    name: 'AirRouterLink',
    props: {
        model: Object,
        id: [Number, String],
        name: String
    },
    data: () => ({}),
    computed: {
        to() {
            const to = {}
            let name = this.name
            if (this.model) {
                name = name.charAt(0).toUpperCase() + name.slice(1)
                name = `${this.model.name}${name}`
            }
            to['name'] = name
            if (this.id && this.model)
                to['params'] = {[`${this.model.name}Id`]: this.id}
            return to
        }
    }

}
</script>

<style scoped>

</style>