<template>
    <div class="font-weight-bold my-5" style="line-height: 1.7rem"
         :class="{'title': $vuetify.breakpoint.smAndDown, 'text-h4': $vuetify.breakpoint.mdAndUp}">
        <slot></slot>
    </div>
</template>

<script>
export default {
    name: 'AirHeaderText',
    data: () => ({}),
    methods: {}
}
</script>

<style scoped>

</style>