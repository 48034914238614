<template>
    <div class="body-2 grey--text text--darken-1" @click="$emit('click')">
        <slot></slot>
    </div>
</template>

<script>
export default {
    name: 'AirDescriptionText',
    data: () => ({}),
    methods: {}
}
</script>

<style scoped>

</style>