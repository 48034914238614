const m = require('air-vue-model/model')()
m.name = 'nomenclature'
m.url = 'nomenclatures'

m.default = {
    name: null,
    sku: null,
    company: null,
    price: 0,
    vat: 'none',
    measurement_unit: null,
    vacant_position: false
}

m.vats = [
    {value: 'none', name: 'Без НДС'},
    {value: 'vat0', name: '0%'},
    {value: 'vat10', name: '10%'},
    {value: 'vat20', name: '20%'},
]

m.routes = [
    {name: 'list', component: require('../main/views/nomenclatures/Nomenclatures')},
    {name: 'item', component: require('../main/views/nomenclatures/Nomenclature'), single: true},
]
module.exports = m