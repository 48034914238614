<template>
    <router-link :to="to" :is="!to ? 'div' : 'router-link'">
        <vs-button
            :success="success"
            :active="active"
            :disabled="disabled"
            :transparent="transparent"
            :floating="floating"
            :flat="flat"
            :loading="loading"
            :border="border"
            :danger="danger"
            :gradient="gradient"
            :class="{'ma-0': noGutters, 'px-2': !noPadding && !small, 'px-1': !noPadding && small}"
            :style="style"
            :color="color"
            :small="small"
            icon @click="$emit('click')" :block="block">
            <air-icon :class="{'mr-2': $slots.default, 'caption': small}" :icon="icon" v-if="icon && left"></air-icon>
            <v-row no-gutters align="center" justify="center" :class="{'caption': small}" class="flex-nowrap">
                <slot v-if="$slots.default"></slot>
            </v-row>
            <air-icon :class="{'ml-2': $slots.default, 'caption': small}" :icon="icon" v-if="icon && !left"></air-icon>
        </vs-button>
    </router-link>
</template>

<script>

import AirIcon from "../helpers/AirIcon";

export default {
    name: 'AirButton',
    props: {
        left: Boolean,
        floating: Boolean,
        active: Boolean,
        transparent: Boolean,
        flat: Boolean,
        icon: String,
        block: Boolean,
        gradient: Boolean,
        color: String,
        danger: Boolean,
        to: Object,
        border: Boolean,
        loading: Boolean,
        success: Boolean,
        noGutters: Boolean,
        disabled: Boolean,
        width: String,
        height: String,
        noPadding: Boolean,
        small: Boolean
    },
    components: {
        AirIcon
    },
    data: () => ({}),
    methods: {},
    computed: {
        currentWidth() {
            if (this.width)
                return this.width
            if (this.icon && !this.$slots.default)
                return this.small ? '28px' : '38px'
        },
        currentHeight() {
            if (this.height)
                return this.height
            return this.small ? '28px' : '38px'
        },
        style() {
            return `width: ${this.currentWidth}; min-width: ${this.currentWidth};
                height: ${this.currentHeight}; max-height: ${this.currentHeight} !important; `
        }
    }
}
</script>

<style scoped>

</style>