<template>
    <v-col class="pa-0">
        <div v-if="label" class="caption mb-1 ml-2">{{ label }}</div>
        <vs-select
            v-if="items.length"
            block
            class="body-2"
            placeholder="Группа"
            :value="currentValue"
            @input="emitValue"
        >
            <vs-option :key="index" v-for="(el, index) in items"
                       :label="!simpleValues ? el[itemText] : el"
                       :value="!simpleValues ? el[itemValue] : el">
                {{ !simpleValues ? el[itemText] : el }}
            </vs-option>
        </vs-select>
    </v-col>
</template>

<script>
export default {
    name: 'AirSelect',
    props: {
        items: Array,
        value: [Number, String, Object],
        label: String,
        itemText: {
            type: String,
            default: 'name'
        },
        itemValue: {
            type: String,
            default: 'id'
        },
        simpleValues: Boolean
    },
    data: () => ({
        val: '1'
    }),
    methods: {
        emitValue(value) {
            this.$emit('input', value)
            this.$emit('change', value)
        }
    },
    computed: {
        currentValue() {
            if (!this.value)
                return ''
            if (typeof this.value === 'object')
                return this.value[this.itemValue].toString()
            return this.value.toString()
        }
    },
    created() {

    }
}
</script>

<style scoped>

</style>