<template>
    <v-col class="pa-0">
        <v-badge overlap offset-y="20" dot offset-x="20" color="red" :value="hasFilters">
            <just-btn many @click="filterDialog = true" color="transparent" height="35" width="35"
                      style="background-color: rgba(0,0,0,0) !important;" class="my-2 px-3"
                      icon="fa filter"></just-btn>
        </v-badge>
        <air-dialog v-model="filterDialog">
            <div class="mb-5 body-2 secondary--text">Фильтр</div>
            <v-text-field hide-details class="mb-5" outlined dense label="Номер заказа"
                          v-model="$order.filters.number"></v-text-field>
            <date-picker class="mb-5" clearable label="Дата начала доставки"
                         v-model="$order.filters.start_date"></date-picker>
            <date-picker clearable class="mb-5" label="Дата окончания доставки"
                         v-model="$order.filters.end_date"></date-picker>
            <v-select outlined dense v-model="$order.filters.company"
                      item-value="inn"
                      item-text="name"
                      clearable hide-details class="mb-5" label="Компания"
                      :items="$company.list"></v-select>
            <v-autocomplete
                @change="clearClientTimeout"
                no-filter
                no-data-text="Данные отсутствуют"
                v-model="$order.filters.client"
                :items="$client.searchList"
                :loading="$client.loadings.list"
                :search-input.sync="$client.filters.search"
                @update:search-input="searchClient"
                item-text="name"
                outlined dense hide-details
                item-value="id" clearable
                label="Клиент"
            >
            </v-autocomplete>
            <v-autocomplete class="mt-5"
                            @change="clearWorkerTimeout"
                            no-filter
                            no-data-text="Данные отсутствуют"
                            v-model="$order.filters.worker"
                            :items="$userCompany.searchList"
                            :loading="$userCompany.loadings.list"
                            :search-input.sync="$userCompany.filters.search"
                            @update:search-input="searchUserCompany"
                            :item-text="userCompanyText"
                            outlined dense hide-details
                            item-value="id" clearable
                            label="Работник"
            >
            </v-autocomplete>
            <v-select outlined dense v-model="$order.filters.has_fiscalized_check" item-value="value" item-text="name"
                      clearable hide-details class="mt-5" label="Имеются фискализированные чеки"
                      :items="[{name: 'Да', value: true}, {name: 'Нет', value: false}]"></v-select>
            <v-select outlined dense v-model="$order.filters.has_declined_check" item-value="value" item-text="name"
                      clearable hide-details class="mt-5" label="Имеются отклоненные чеки"
                      :items="[{name: 'Да', value: true}, {name: 'Нет', value: false}]"></v-select>
            <v-select outlined dense v-model="$order.filters.has_waiting_check" item-value="value" item-text="name"
                      clearable hide-details class="mt-5" label="Имеются чеки в ожидании"
                      :items="[{name: 'Да', value: true}, {name: 'Нет', value: false}]"></v-select>
            <v-row no-gutters align="center" class="mt-5">
                <just-btn :loading="loading" @click="loadWithFilter">Применить</just-btn>
                <v-spacer></v-spacer>
                <div class="secondary--text body-2 cursor-pointer" @click="clearFilters">Сбросить</div>
            </v-row>
        </air-dialog>
    </v-col>
</template>

<script>
import DatePicker from "../../../components/inputs/DatePicker";

export default {
    name: 'OrdersFilters',
    components: {
        DatePicker
    },
    data: () => ({
        filterDialog: false,
        clientTimeout: null,
        workerTimeout: null,
        loading: false
    }),
    methods: {
        clearWorkerTimeout() {
            setTimeout(() => {
                clearTimeout(this.workerTimeout)
            }, 200)
        },
        clearClientTimeout() {
            setTimeout(() => {
                clearTimeout(this.clientTimeout)
            }, 200)
        },
        loadWithFilter() {
            this.loading = true
            this.$order.loadList().then(() => {
                this.filterDialog = false
                this.loading = false
            })
        },
        userCompanyText(val) {
            if (!val || !val.user) return null
            return `${val.user.first_name} ${val.user.last_name}`
        },
        clearFilters() {
            this.$order.filters.start_date = null
            this.$order.filters.end_date = null
            this.$order.filters.client = null
            this.$order.filters.worker = null
            this.$order.filters.company = null
            this.$order.filters.number = null
            this.$order.filters.has_fiscalized_check = null
            this.$order.filters.has_declined_check = null
            this.$order.filters.has_waiting_check = null
            this.$order.loadList()
            this.filterDialog = false
        },
        searchClient(val, ekk) {
            clearTimeout(this.clientTimeout)
            this.clientTimeout = setTimeout(() => {
                this.$client.loadList({}, {setToModel: false}).then(res => {
                    this.$client.searchList = res.results
                })
            }, 300)
        },
        searchUserCompany(val) {
            console.log('kek2')
            clearTimeout(this.workerTimeout)
            this.workerTimeout = setTimeout(() => {
                this.$userCompany.loadList({}, {setToModel: false}).then(res => {
                    this.$userCompany.searchList = res.results
                })
            }, 300)
        }
    },
    computed: {
        hasFilters() {
            const filters = this.$order.filters
            return filters.start_date || filters.end_date || filters.client
                || filters.worker || filters.number || filters.has_fiscalized_check !== null
                || filters.has_declined_check !== null || filters.has_waiting_check !== null
        }
    },
    watch: {}
}
</script>

<style scoped>

</style>