<template>
    <company-item-template :model="$company">
        <template v-slot:item="{item}">
            <just-text-field label="Наименование" v-model="item.name" :rules="[$rules.required]"></just-text-field>
            <just-text-field
                :disabled="$route.params.companyInn !== 'new'"
                class="mt-3" label="ИНН" v-model="item.inn"
                :rules="[$rules.required]"></just-text-field>
            <just-text-field class="mt-3" label="Основной email" v-model="item.email"
                             :rules="[$rules.required, $rules.email]"></just-text-field>
            <just-select class="mt-3" label="Система налогооблажения" v-model="item.sno" :items="$company.sno"
                         item-text="name"
                         item-value="value"></just-select>
            <just-select class="mt-3" item-text="name" item-value="inn"
                         v-model="item.head_company"
                         return-object
                         clearable
                         :items="$company.list" label="Головная компания"></just-select>
            <div class="mt-5 body-2 grey--text text--darken-2">Дополнительные функции</div>
            <v-row no-gutters align="center" class="mt-5">
                <just-btn
                    outlined
                    @click="openDialog">Установить пароль компании
                </just-btn>
                <just-btn class="ml-3"
                          outlined
                          @click="openDialogSettings">Настройки компании
                </just-btn>
            </v-row>
            <v-dialog v-model="dialog" max-width="500">
                <v-card class="pa-3">
                    <div class="body-2 my-3 grey--text text--darken-2">Установка пароля для компании для быстрой
                        авторизации работников с помощью кода
                    </div>
                    <air-form v-model="valid">
                        <just-text-field type="password" :rules="[$rules.required, $rules.password]"
                                         v-model="password"></just-text-field>
                    </air-form>
                    <just-btn
                        :loading="loading"
                        :disabled="!valid"
                        class="mt-3"
                        @click="setCompanyPassword">Сохранить
                    </just-btn>
                </v-card>
            </v-dialog>
            <v-dialog v-model="settingsDialog" max-width="500">
                <v-card class="pa-3">
                    <div class="body-2 my-3 grey--text text--darken-2">Настройки компании
                    </div>
                    <v-col class="pa-0">
                        <v-checkbox class="mt-1" dense hide-details style="margin-left: -15px"
                                    v-model="settings.second_sale_available"
                                    label="Возможность создать 2 чека продажи подряд"></v-checkbox>
                        <v-checkbox class="mt-1" dense hide-details style="margin-left: -15px"
                                    v-model="settings.partial_return_available"
                                    label="Возможность сформировать частичный возврат"></v-checkbox>
                        <v-checkbox class="mt-1" dense hide-details style="margin-left: -15px"
                                    v-model="settings.can_worker_create_orders"
                                    label="Может ли работник создавать заказы"></v-checkbox>
                        <v-checkbox class="mt-1" dense hide-details style="margin-left: -15px"
                                    v-model="settings.only_vacant_positions_in_order"
                                    label="При создании заказа работником, он может содержать только свободные позиции"></v-checkbox>
                        <v-checkbox class="mt-1" dense hide-details style="margin-left: -15px"
                                    v-model="settings.decrease_amount_in_check"
                                    label="Возможность уменьшить количество товара"></v-checkbox>
                        <v-checkbox class="mt-1" dense hide-details style="margin-left: -15px"
                                    v-model="settings.increase_amount_in_check"
                                    label="Возможность увеличивать количество товара"></v-checkbox>
                        <v-checkbox class="mt-1" dense hide-details style="margin-left: -15px"
                                    v-model="settings.change_nomenclature_price_in_check"
                                    label="Возможность изменять цену при создании чека"></v-checkbox>
                        <v-checkbox class="mt-1" dense hide-details style="margin-left: -15px"
                                    v-model="settings.change_vacant_positions_price_in_check"
                                    label="Возможность изменять цену свободной позиции при создании чека"></v-checkbox>
                        <v-checkbox class="mt-1" dense hide-details style="margin-left: -15px"
                                    v-model="settings.can_worker_add_positions_which_not_in_order"
                                    label="Возможность добавлять новые позиции в заказ"></v-checkbox>
                        <v-checkbox class="mt-1" dense hide-details style="margin-left: -15px"
                                    v-model="settings.search_allow"
                                    label="Возможность поиска по позициям в заказе"></v-checkbox>
                        <v-checkbox class="mt-1" dense hide-details style="margin-left: -15px"
                                    v-model="settings.full_return_should_be_paid_with_the_same_payment_method"
                                    label="Осуществлять возврат теми же способами оплаты, что и в чеке продажи"></v-checkbox>
                        <v-checkbox class="mt-1" dense hide-details style="margin-left: -15px"
                                    v-model="settings.company_works_with_client_phone"
                                    label="Может ли компания отправлять чеки на номер телефона"></v-checkbox>
                    </v-col>
                    <just-btn
                        :loading="loadingSettings"
                        class="mt-3"
                        @click="saveSettings">Сохранить
                    </just-btn>
                </v-card>
            </v-dialog>
        </template>
    </company-item-template>
</template>

<script>

import CompanyItemTemplate from "./CompanyItemTemplate";

export default {
    name: 'Company',
    components: {
        CompanyItemTemplate
    },
    data: () => ({
        dialog: false,
        settingsDialog: false,
        password: '',
        loading: false,
        loadingSettings: false,
        valid: false,
        settings: {}
    }),
    methods: {
        openDialog() {
            this.password = ''
            this.dialog = true
        },
        openDialogSettings() {
            this.settings = this.$company.copy(this.$company.item.settings)
            this.settingsDialog = true
        },
        setCompanyPassword() {
            this.loading = true
            this.$company.sendPostSingle('set_password', this.$company.item.inn, {
                password: this.password
            }).then(() => {
                this.dialog = false
                this.loading = false
                this.$snackbar.success('Пароль успешно установлен')
            })
        },
        saveSettings() {
            this.loadingSettings = true
            this.$company.sendPutSingle('set_settings', this.$company.item.inn, this.settings).then(() => {
                this.settingsDialog = false
                this.$company.item.settings = this.settings
                this.loadingSettings = false
                this.$snackbar.success('Настройки успешно сохранены')
            })
        }
    },
}
</script>

<style scoped>

</style>