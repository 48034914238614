<template>
    <v-divider :vertical="vertical" class="grey lighten-3"/>
</template>

<script>
export default {
    name: 'AirDivider',
    props: {
        vertical: Boolean
    },
}
</script>
