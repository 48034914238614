<!--<template>-->
<!--    <air-list-template :model="$client" header="Контрагенты">-->
<!--        <template v-slot:item="{item}">-->
<!--            <div>-->
<!--            <div>{{ item.name }}</div>-->
<!--            <div class="caption">ИНН: {{ item.inn }}</div>-->
<!--            &lt;!&ndash;            <router-link class="black&#45;&#45;text" style="width: 100%" :to="{name: 'userCompanyGeneral', params: {&ndash;&gt;-->
<!--            &lt;!&ndash;                    companyInn: item.company.inn&ndash;&gt;-->
<!--            &lt;!&ndash;                }}">&ndash;&gt;-->
<!--            &lt;!&ndash;                &lt;!&ndash;                <company-view :item="item.company"></company-view>&ndash;&gt;&ndash;&gt;-->
<!--            &lt;!&ndash;            </router-link>&ndash;&gt;-->
<!--                </div>-->
<!--        </template>-->
<!--    </air-list-template>-->
<template>
    <v-col class="pa-0" style="position: relative">
        <v-progress-linear :active="$client.loadings.list || $client.loadings.item"
                           indeterminate style="position: absolute; top: -12px"
                           color="primary"
        ></v-progress-linear>
        {{model}}
        <v-row no-gutters align="center" class="flex-nowrap px-5 my-3">
            <v-col>
                <air-header-text v-if="!sublist">{{ _header }}</air-header-text>
                <air-title-text v-else>{{ _header }}</air-title-text>
            </v-col>
            <v-col cols="auto">
                <v-row no-gutters align="center">
                    <slot name="actions"></slot>
                    <just-btn disabled :to="_to()">Создать</just-btn>
                </v-row>
            </v-col>
        </v-row>
        <v-col class="pa-0 mb-5" v-if="$slots.filters">
            <v-row no-gutters align="center">
                <slot name="filters">
                </slot>
            </v-row>
            <air-divider></air-divider>
        </v-col>
        <v-col class="mt-5 pa-0" v-if="$slots.items">
            <slot name="items"></slot>
        </v-col>
        <v-col class="pa-0 body-2 px-5" v-else-if="$client.list.length">
            <air-card class="px-0 py-3">
                <v-col class="pa-0" v-for="(item, index) in $client.list" :key="index">
                    <air-divider v-if="index"></air-divider>
                    <router-link style="width: 100%" :is="_to(item) ? 'router-link' : 'div'" :to="_to(item)"
                                 class="black--text">
                        <v-col class="py-2 px-5 selected-item">
                            <div>{{ item.name }}</div>
                            <div class="caption">ИНН: {{ item.inn }}</div>
                        </v-col>
                    </router-link>
                </v-col>
            </air-card>
        </v-col>
        <v-row no-gutters align="center" justify="end"
               v-if="model && model.pagination.page_size !== 'all' && model.pagination.last_page >1"
               class="px-5 mt-5 mb-3">
            <air-change-page :last-page="model.pagination.last_page"
                             v-model="model.pagination.page"></air-change-page>
        </v-row>
    </v-col>
</template>

<script>
import AirListTemplate from "../../../components/template/list/AirListTemplate";
import AirItemActionsTemplate from "../../../components/template/actions/AirItemActionsTemplate";

export default {
    name: 'Clients',
    components: {
        AirListTemplate,
        AirItemActionsTemplate
    },
    props: {
        header: String,
        items: Array,
        sublist: Boolean,
        showTopPagination: Boolean,
    },
    data: () => ({
        toItemName: 'item',
    }),
    computed: {
        _header() {
            if (this.header)
                return this.header
            if (this.model)
                return this.model.texts.list
        },
        _list() {
            if (this.items)
                return this.items
            if (this.model)
                return this.model.list
            return []
        },
    },
    methods: {
        _to(value = null) {
            if (this.toItemName && this.$client) {
                const routeName = `${this.$client.name}${this.toItemName.charAt(0).toUpperCase() + this.toItemName.slice(1)}`
                return {
                    name: routeName, params: {[this.$client.routeParam]: value ? value[this.$client.pk] : 'new'}
                }
            } else
                return null
        }
    },
    created() {
        this.$client.loadList({active: true})
    }
}
</script>

<style scoped>

</style>