<template>
    <v-col class="pa-0">
        <div v-if="label" class="caption mb-1 secondary--text" style="line-height: 0.75rem;">{{ label }}</div>
        <v-text-field block :value="currentValue" @input="$emit('input', $event)"
                      style="border-radius: 6px"
                      :readonly="readonly"
                      :disabled="disabled"
                      :type="type"
                      :background-color="!disabled && !readonly ? color : undefined"
                      :rules="rules"
                      :placeholder="placeholder"
                      :error="!!error"
                      :error-messages="errorMessages"
                      @click="inputClick"
                      @focus="focus"
                      @blur="$emit('blur')"
                      filled
                      rounded
                      hide-details="auto"
                      dense>
        </v-text-field>
    </v-col>
</template>

<script>
export default {
    name: 'JustTextField',
    props: {
        placeholder: String,
        label: String,
        value: [String, Number],
        type: String,
        state: String,
        rules: Array,
        color: {
            type: String,
            default: '#f5f5f5'
        },
        readonly: Boolean,
        visiblePassword: Boolean,
        disabled: Boolean,
        errorMessages: [String, Array]
    },
    data: () => ({
        clicked: false,
        valid: false
    }),
    methods: {
        inputClick() {
            this.clicked = true
            this.$emit('click')
        },
        focus() {
            this.clicked = true
            this.$emit('focus')
        },
    },
    created() {

    },
    computed: {
        error() {
            for (const el in this.rules) {
                const message = this.rules[el](this.value)
                if (message !== true) {
                    this.valid = false
                    return message
                }
            }
            this.valid = true
        },
        currentValue() {
            if (this.value === null)
                return ''
            return this.value
        }
    }
}
</script>

<style>
.vs-input__label {
    max-width: 250px;
}

</style>