<template>
    <router-link :to="to" :is="!to ? 'v-col' : 'router-link'" @click="$emit('click')"
                 class="pa-0 vs-card py-4 px-3" :style="to && click ? 'cursor: pointer' : 'cursor: auto'">
        <v-row no-gutters align="start" class="flex-nowrap">
            <v-col>
                <air-title-text class="mt-0 mb-0">
                    <div>
                        <slot v-if="$slots.title" name="title"></slot>
                        <div v-else>{{ title }}</div>
                    </div>
                </air-title-text>
                <air-description-text v-if="$slots.description || description" class="mt-1">
                    <div>
                        <slot name="description" v-if="$slots.description"></slot>
                        <div v-else>{{ description }}</div>
                    </div>
                </air-description-text>
            </v-col>
            <v-col cols="auto">
                <div>
                    <v-row no-gutters align="center">
                        <slot name="actions"></slot>
                    </v-row>
                </div>
            </v-col>
        </v-row>
        <v-col :class="{'mt-3': title || $slots.title || description || $slots.description}" class="pa-0"
               v-if="$slots.default">
            <slot></slot>
        </v-col>
    </router-link>
</template>

<script>

import AirTitleText from "../texts/AirTitleText";
import AirDescriptionText from "../texts/AirDescriptionText";

export default {
    name: 'JustCard',
    components: {
        AirTitleText,
        AirDescriptionText
    },
    props: {
        primary: Boolean,
        title: String,
        description: String,
        to: Object
    },
}
</script>
