let theme = {
    primary: '#5a3cc4',
    disabled: '#C5C5C5',
    secondary: '#8C8C8C',
    input_color: '#ECECEC',
    white_button: '#f5f5f5',
    wait: '#ffc107',
    check_success: '#28a745',
    success: '#17c964',
    danger: '#f2135d',
    warning: '#ff8200',
    dark: '#242145',
    transparent: 'none'
}

export default theme