<template>
    <v-tab-item
        :key="1"
        :value="`tab-1`">
        <v-card flat class="px-5 py-5">
            <report-view :item="item"></report-view>
        </v-card>
    </v-tab-item>
</template>

<script>
import ReportView from "./ReportView";

export default {
    name: "generalReport",
    components: {
        ReportView
    },
    props: {
        item: Object
    },
    data: () => ({}),
}
</script>

<style scoped>

</style>