<template>
    <air-dialog v-if="item" :value="value" @close="$emit('input', false)" @click:outside="$emit('input', false)">
        <v-row :style="$vuetify.breakpoint.smAndUp ? 'min-width: 700px' : ''" no-gutters align="center" class="mb-2">
            <div class="font-weight-bold mx-3">{{ $check.getCheckType(item).text }} {{ item.sum / 100 }} ₽</div>
            <v-spacer></v-spacer>
            <div>
                <last-check-in-order-information class="mr-4" :check="item"></last-check-in-order-information>
            </div>
        </v-row>
        <div class="mx-3 mb-2">
            <pre class="caption mb-2 pt-1"
                 v-if="item.error_text && Object.keys(item.error_text).length">{{ item.error_text }}</pre>
            <div class="mb-1">
                <span style="color: #828282; font-size: 14px">Дата чека</span>
                <br>
                <span>{{ parseDate(item.created_at) }}</span>
            </div>
            <div class="mb-1">
                <span style="color: #828282; font-size: 14px">Получатель</span>
                <br>
                <span>{{ item.client.name }}</span>
            </div>
            <div class="mb-1">
                <span
                    style="color: #828282; font-size: 14px">{{
                        item.client_email ? 'E-mail получателя' : 'Телефон получателя'
                    }}</span>
                <br>
                <span>{{ item.client_email || item.client_phone }}</span>
            </div>
            <div class="mb-1">
                <span style="color: #828282; font-size: 14px">Место расчетов</span>
                <br>
                <span>{{ item.payment_address || 'Отсутствует' }}</span>
            </div>
            <div v-for="payment in item.payments" class="mb-1">
                <span style="color: #828282; font-size: 14px">
                    {{ getPaymentType(payment.type) }}</span>
                <br>
                <span>{{ payment.sum / 100 }} ₽</span>
            </div>
            <div class="caption grey--text text--darken-2 mt-3">uuid: {{ item.uuid }}</div>
            <div class="caption grey--text text--darken-2 mb-5">external uuid: {{ item.external_callback_uuid }}</div>
        </div>
        <v-divider class="mb-2"></v-divider>
        <div class="mx-3">
            <div v-for="(check_item, index) in item.items">
                <v-col class="pa-0">
                    <v-divider class="my-1" v-if="index"></v-divider>
                    <v-row no-gutters>
                        <div class="font-weight-bold text-decoration-underline">{{ check_item.nomenclature.sku }}</div>
                        <v-spacer></v-spacer>
                        <div class="font-weight-bold mb-2" style=" white-space: nowrap;"> {{ check_item.sum / 100 }} ₽
                        </div>
                        <div class="secondary--text ml-2 mb-2" style="white-space: nowrap;">{{
                                check_item.quantity
                            }}{{ check_item.nomenclature.measurement_unit }}
                        </div>
                    </v-row>
                    <v-row no-gutters>
                        <div class="secondary--text mb-2">{{ check_item.nomenclature.name }}</div>
                        <v-spacer></v-spacer>
                        <div class="secondary--text mb-2" style="white-space: nowrap;">{{
                                check_item.price / 100
                            }} ₽
                        </div>
                        <div class="secondary--text ml-2 mb-2" style=" white-space: nowrap;">
                            1{{ check_item.nomenclature.measurement_unit }}
                        </div>
                    </v-row>
                </v-col>
            </div>
            <v-divider class="mb-3"></v-divider>
            <div v-if="getQR" class="d-flex justify-center"><img :src="getQR"></div>
            <v-row no-gutters justify="center" class="mt-3">
                <a target="_blank" :href="item.receipt_url" v-if="item.receipt_url">
                    <div class="primary--text cursor-pointer text-decoration-underline">Ссылка на чек</div>
                </a>
            </v-row>
            <div @click="checkData ? checkData = null : showCheckData()"
                 class="caption secondary--text cursor-pointer text-decoration-underline mt-3">
                {{ !checkData ? 'Показать' : 'Скрыть' }} данные интеграции
            </div>
            <json-viewer class="mt-5"
                         :expand-depth=999
                         copyable
                         v-if="checkData" :value="checkData"></json-viewer>
        </div>

    </air-dialog>
</template>

<script>
import LastCheckInOrderInformation from "../LastCheckInOrderInformation";
import JsonViewer from 'vue-json-viewer'

export default {
    name: "OrderCheckModal",
    props: {
        value: Boolean,
        item: Object
    },
    components: {
        LastCheckInOrderInformation,
        JsonViewer
    },
    data: () => ({
        checkStatus: null,
        checkDataLoading: null,
        checkData: null
    }),
    created() {
    },
    computed: {
        getQR() {
            if (this.item.receipt_text) {
                let qr = require('qr-encode')
                let dataURI = qr(this.item.receipt_text, {type: 6, size: 4, level: 'Q'})
                let img = new Image()
                img.src = dataURI
                return dataURI
            }

        }
    },
    methods: {
        showCheckData() {
            this.checkDataLoading = true
            this.$check.sendGetSingle('check_data', this.item.uuid).then(res => {
                this.checkDataLoading = false
                this.checkData = res
            })

        },
        parseDate(val) {
            return window.moment.utc(val).local().format('DD.MM.YYYY HH:mm:ss')
        },
        getPaymentType(payment) {
            if (payment === 1) {
                return 'Безналичный расчет'
            } else if (payment === 0) {
                return 'Наличный расчет'
            }
        },
    }
}
</script>

<style>
.jv-code {
    padding: 0 !important;
}
</style>