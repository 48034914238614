const m = require('air-vue-model/model')()
m.name = 'orderCheck'
m.url = 'checks'
m.pk = 'uuid'

m.type = [
    {value: 1, name: 'Оплата'},
    {value: 2, name: 'Частичный возврат'},
    {value: 3, name: 'Полный возврат'},
]

m.routes = [
    {name: 'list', component: require('../main/views/orderChecks/OrderChecks')},
    {name: 'item', component: require('../main/views/orderChecks/OrderCheck'), single: true},
]
m.default = {
    number: null,
}

m.getCheckType = (check) => {
    if (check.type === 1) return {'color': 'green', text: 'Продажа', icon: 'mdi-arrow-up-bold'}
    if (check.type === 2) return {'color': 'red', text: 'Возврат', icon: 'mdi-arrow-down-bold'}
    if (check.type === 3) return {'color': 'red', text: 'Возврат', icon: 'mdi-arrow-down-bold'}
}


m.getCheckStatus = (check) => {
    if (check.status === 'done') return {color: 'green', text: 'Фискализирован', icon: 'mdi-check-bold'}
    if (check.status === 'wait') return {
        color: 'amber',
        text: 'Ожидает',
        icon: 'mdi-clock-time-eight-outline'
    }
    if (check.status === 'created') return {color: 'primary', text: 'Создан', icon: 'mdi-database-outline'}
    if (check.status === 'fail') return {color: 'red', text: 'Ошибка', icon: 'mdi-alert-circle-outline'}
}

m.reportData = {
    checksCount: [
        {'title': 'Успешные чеки', key: 'done_checks_count'},
        {'title': 'Чеки в ожидании', key: 'waiting_checks_count'},
        {'title': 'Чеки с ошибкой', key: 'failed_checks_count'},
    ],
    values: [
        {'title': 'Продажа', 'key': 'payment'},
        {'title': 'Возврат', 'key': 'return'},
        {'title': 'Итого', 'key': 'total'},
    ],
    currentValues: [
        {'title': 'Количество чеков', key: 'count'},
        {'title': 'Наличные', key: 'cash'},
        {'title': 'Безналичные', key: 'non_cash'},
    ]
}

module.exports = m