<template>
    <air-item-template :model="$client">
        <template v-slot:form="{item}">
            <just-text-field label="Наименование" v-model="item.name" :rules="[$rules.required]"></just-text-field>
            <just-text-field :error="error" :error-messages="errorMessage" class="mt-3" label="ИНН" v-model="item.inn"
                             :rules="[$rules.required]"></just-text-field>
            <just-select v-if="!item.id" class="mt-3" item-text="name" item-value="inn" v-model="item.company"
                         :items="$company.list"
                         label="Компания"></just-select>
        </template>
        <template v-slot:item="{item}">
            <contact-list class="mt-4"></contact-list>
            <air-divider class="mb-3 mt-4"></air-divider>
            <delivery-address-list></delivery-address-list>
        </template>
    </air-item-template>
</template>

<script>

import AirItemTemplate from "../../../components/template/item/AirItemTemplate";
import ContactList from "./contacts/ContactList";
import DeliveryAddressList from "./deliveryAddresses/DeliveryAddressList";

export default {
    name: 'Client',
    components: {
        DeliveryAddressList,
        AirItemTemplate,
        ContactList
    },
    data: () => ({
        errorMessage: null
    }),
    methods: {},
    computed: {
        error() {
            if ((this.$client.item.inn.length === 10 || this.$client.item.inn.length === 12) && !isNaN(this.$client.item.inn)) {
                this.errorMessage = null
                return false
            } else {
                this.errorMessage = 'ИНН может содержать 10 или 12 цифр.'
                return true
            }
        }
    }
}
</script>

<style scoped>

</style>