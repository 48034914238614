<template>
    <v-col class="py-3 px-5">
        <air-header-text>Отчеты</air-header-text>
        <v-row>
            <v-col cols="3" v-for="(el, index) in reports" :key="index">
                <v-card :to="el.to" class="px-3 py-5 rounded-lg"
                        style="box-shadow: 0 3px 8px 0 rgba(34, 60, 80, 0.2) !important;">
                    <v-row no-gutters class="flex-column" align="center">
                        <v-icon color="grey" size="40">{{ el.icon }}</v-icon>
                        <div class="mt-3 grey--text text--darken-2 body-2">{{ el.name }}</div>
                    </v-row>
                </v-card>
            </v-col>
        </v-row>
    </v-col>
</template>

<script>
export default {
    name: "Reports",
    data: () => ({
        reports: [
            {name: 'Отчет по чекам', to: {name: 'orderReport'}, icon: 'mdi-file-chart-outline'},
        ]
    })
}
</script>

<style scoped>

</style>