<template>
    <div>{{ $userDevice.item }}</div>
</template>

<script>

export default {
    name: 'UserDevice',
    components: {},
    data: () => ({}),
    methods: {}
}
</script>

<style scoped>

</style>