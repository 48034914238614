<template>
    <v-main class="background" style="padding: 56px 0 0 56px; !important;">
        <v-app-bar clipped-left app style="box-shadow: 0px 3px 8px 0px rgba(34, 60, 80, 0.2) !important;" class="white">
            <div class="secondary--text">COREX.FISCAL</div>
            <v-spacer></v-spacer>
            <v-row no-gutters align="center" justify="end">
                <v-row no-gutters align="end" justify="center" class="flex-column px-3"
                       v-if="$vuetify.breakpoint.mdAndUp">
                    <div style="line-height: 15px" class="body-2">{{ user.last_name }} {{ user.first_name }}</div>
                    <div style="line-height: 15px" class="caption grey--text">администратор</div>
                </v-row>
                <vs-avatar history circle>
                    <img
                        src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQR2_tcPs_s9PCoz4AzZ_ObQBJNYnOIlJJ4XGBNkQvHo87D15KBaYoBoJ98GEuEzJHUkU7xK8EeXnmv3Q&usqp=CAU"
                        alt="">
                </vs-avatar>
            </v-row>
            <div style="height: 40px" class="mx-3">
                <air-divider vertical></air-divider>
            </div>
            <air-button icon="sign-out-alt" @click="logout" transparent></air-button>
        </v-app-bar>
        <drawer></drawer>
        <v-col class="pa-0 fill-height background" v-if="ready">
            <router-view></router-view>
        </v-col>
        <v-snackbar
            v-model="$snackbar.value"
            timeout="4000"
            style="position:fixed;"
            top
            right
            :color="$snackbar.color"
        >
            {{ $snackbar.text }}

            <template v-slot:action="{ attrs }">
                <v-btn
                    color="white"
                    text
                    v-bind="attrs"
                    @click="$snackbar.value = false"
                >
                    X
                </v-btn>
            </template>
        </v-snackbar>
    </v-main>
</template>

<script>
import Drawer from "../../components/Drawer";

export default {
    name: 'Home',
    components: {
        Drawer
    },
    data: () => ({
        ready: false
    }),
    methods: {
        logout() {
            this.$auth.logout()
            window.location.href = '/login'
        }
    },
    computed: {
        user() {
            return this.$auth.user
        }
    },
    created() {
        this.$company.loadList().then(() => {
            this.ready = true
        })
    }
}
</script>

<style scoped>

</style>