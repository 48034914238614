const m = require('air-vue-model/model')()
m.name = 'userCompany'
m.url = 'user_companies'
m.routes = []
m.searchList = []
m.roles = [
    {value: 1, name: 'Администратор'},
    {value: 2, name: 'Работник'},
    {value: 3, name: 'Пользователь'},
]

m.status = [
    {value: true, name: 'Активен'},
    {value: false, name: 'Заблокирован'},
]
m.setPagination({page_size: 100})
module.exports = m