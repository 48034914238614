<template>
    <v-col class="pa-0" style="position: relative">
        <v-progress-linear :active="model.loadings.save || model.loadings.destroy"
                           indeterminate style="position: absolute; top: -20px"
                           color="primary"></v-progress-linear>
        <v-col class="pa-0 px-5">
            <air-item-actions-template :model="model" :valid="valid"></air-item-actions-template>
            <air-card class="mt-5">
                <air-form v-model="valid" class="mt-5">
                    <slot name="form" v-bind:item="item"></slot>
                </air-form>
                <slot name="item" v-bind:item="item"></slot>
            </air-card>
        </v-col>
    </v-col>
</template>

<script>
import AirItemActionsTemplate from "../actions/AirItemActionsTemplate";

export default {
    name: 'AirItemTemplate',
    components: {
        AirItemActionsTemplate
    },
    props: {
        model: Object
    },
    data: () => ({
        valid: false
    }),
    methods: {},
    computed: {
        item() {
            return this.model.item
        }
    }
}
</script>

<style scoped>

</style>