<template>
    <v-col class="pa-0" style="position: relative">
        <v-progress-linear :active="model.loadings.list || model.loadings.item"
                           indeterminate style="position: absolute; top: -12px"
                           color="primary"
        ></v-progress-linear>
        <v-row no-gutters align="center" class="flex-nowrap px-5 my-3">
            <v-col>
                <v-row no-gutters align="center">
                    <air-header-text v-if="!sublist">{{ _header }}</air-header-text>
                    <air-title-text v-else>{{ _header }}</air-title-text>
                    <div class="body-2 grey--text ml-5 mt-3">Количество: {{ $order.pagination.total }}</div>
                </v-row>
            </v-col>
            <v-col cols="auto">
                <v-row no-gutters align="center">
                    <orders-filters></orders-filters>
                    <slot name="actions"></slot>
                    <just-btn disabled many :to="_to()" floating>Создать</just-btn>
                </v-row>
            </v-col>
        </v-row>
        <v-col class="pa-0 mb-5" v-if="$slots.filters">
            <v-row no-gutters align="center">
                <slot name="filters">
                </slot>
            </v-row>
            <air-divider></air-divider>
        </v-col>
        <v-col class="pa-0 body-2 px-5">
            <air-card class="px-0 py-3">
                <v-data-table
                    :headers="headers"
                    :items="_list"
                    :items-per-page="20"
                    loading-text="Заказы загружаются"
                    no-data-text="Заказов нет"
                    hide-default-footer
                    @click:row="openOrderDetail"
                >
                    <template v-slot:item.date="{item}">
                        <div class="text-none">{{ getCalendarDateTime(item.date) }}</div>
                    </template>
                    <template v-slot:item.sum="{item}">
                        <div class="text-none">{{ item.sum / 100 }}</div>
                    </template>
                    <template v-slot:item.delivery_date="{item}">
                        <div class="text-none">{{ getCalendarDateTime(item.delivery_date) }}</div>
                    </template>
                    <template v-slot:item.check_count="{item}">
                        <v-row no-gutters align="center" class="flex-nowrap">
                            <div class="green--text">{{ item.fiscalized_check_count }}</div>
                            <div class="caption grey--text" style="margin: 0 2px">/</div>
                            <div class="red--text">{{ item.declined_check_count }}</div>
                            <div class="caption grey--text" style="margin: 0 2px">/</div>
                            <div class="secondary--text">{{ item.check_count }}</div>
                        </v-row>
                    </template>
                    <template v-slot:item.worker="{item}">
                        <div v-if="item.worker && item.worker.user">{{ item.worker.user.first_name }}
                            {{ item.worker.user.last_name }}
                        </div>
                    </template>
                    <template v-slot:item.last_check_information="{item}">
                        <last-check-in-order-information center :order="item"></last-check-in-order-information>
                    </template>
                </v-data-table>
            </air-card>
        </v-col>
        <v-row no-gutters align="center" justify="end"
               v-if="model && model.pagination.page_size !== 'all' && model.pagination.last_page >1"
               class="px-5 mt-5 mb-3">
            <air-change-page :last-page="model.pagination.last_page"
                             v-model="model.pagination.page"></air-change-page>
        </v-row>

    </v-col>

</template>

<script>

import AirHeaderText from "../../../components/texts/AirHeaderText";
import AirTitleText from "../../../components/texts/AirTitleText";
import LastCheckInOrderInformation from "./LastCheckInOrderInformation";
import OrdersFilters from "./OrdersFilters";

export default {
    name: 'OrderListTemplate',
    data: () => ({
        filterDialog: false,
        inn: null,
        client: null,
        order_number: null,
        start_date: null,
        end_date: null,
        menu: false,
        menuEnd: false,
        loading: false,
        headers: [
            {text: 'Номер заказа', value: 'number', sortable: false},
            {text: 'Дата', value: 'date', sortable: false},
            {text: 'Дата доставки', value: 'delivery_date', sortable: false},
            {text: 'Компания', value: 'company.name', sortable: false},
            {text: 'Получатель', value: 'client.name', sortable: false},
            {text: 'Курьер', value: 'worker', sortable: false},
            {text: 'Адрес доставки', value: 'delivery_address.address', sortable: false},
            {text: 'Сумма', value: 'sum', sortable: false},
            {text: 'Чеки', value: 'check_count', sortable: false},
            {text: 'Последний чек', value: 'last_check_information', sortable: false},
        ],
        table_settings: {
            disablePagination: true,
            disableItemsPerPage: true
        }
    }),
    props: {
        header: String,
        model: Object,
        items: Array,
        sublist: Boolean,
        showTopPagination: Boolean,
        toItemName: {
            type: String,
            default: 'item'
        }
    },
    components: {
        AirHeaderText,
        AirTitleText,
        LastCheckInOrderInformation,
        OrdersFilters
    },
    computed: {
        _header() {
            if (this.header)
                return this.header
            if (this.model)
                return this.model.texts.list
        },
        _list() {
            if (this.items)
                return this.items
            if (this.model)
                return this.model.list
            return []
        }

    },
    methods: {
        _to(value = null) {
            if (this.toItemName && this.model) {
                const routeName = `${this.model.name}${this.toItemName.charAt(0).toUpperCase() + this.toItemName.slice(1)}`
                return {
                    name: routeName, params: {[this.model.routeParam]: value ? value[this.model.pk] : 'new'}
                }
            } else
                return null
        },
        getCalendarDateTime(date) {
            return moment(date).local().format('DD.MM.YYYY')
        },
        openOrderDetail(item) {
            this.$router.push({
                name: 'orderItem',
                params: {orderUuid: item.uuid}
            })
        },
        filter() {
            this.loading = true
            this.$order.loadList({...this.$order.filters}).then(res => {
                this.items = res.results
                this.loading = false
            })
            this.filterDialog = false
            this.menu = false
            this.menuEnd = false

        },
        menuOn() {
            this.menu = true
        },
        menuEndOn() {
            this.menuEnd = true
        }
    },
    created() {
        if (this.model) {
            this.model.loadList({
                page: this.model.pagination.page
            })
        }

    }
}
</script>

<style scoped>
.selected-item {
    transition: 0.3s all;
}

.selected-item:hover {
    background: rgba(139, 119, 207, 0.05);
}
</style>
