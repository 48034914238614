<template>
    <air-list-template :model="$checkout" header="Кассы">
        <template v-slot:item="{item}">
            <v-row no-gutters align="center">
                <div>{{ item.name }}</div>
              <v-icon size="16px" class="ml-2" color="green" v-if="item.default">fa-check</v-icon>
                <v-spacer></v-spacer>
                <v-icon size="20" :color="item.validated ? 'green' : 'red'">mdi-circle</v-icon>
            </v-row>
        </template>
    </air-list-template>
</template>

<script>
import AirListTemplate from "../../../components/template/list/AirListTemplate";

export default {
    name: 'Checkouts',
    components: {
        AirListTemplate
    },
    data: () => ({}),
    methods: {},
}
</script>

<style scoped>

</style>