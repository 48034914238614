<template>
    <v-card v-if="item" style="max-width: 1200px; width: 100%; margin: 0 auto; min-height: 100vh" class="pa-4">
        <v-row no-gutters justify="center" style=" width: 100%;position: absolute;left: 0;">
            <just-btn style="min-width: 85px;" class="mx-2" @click="tab = 'goods'"
                      :color="tab === 'goods' ? 'primary' : 'white_button'">Товары
            </just-btn>
            <just-btn many style="min-width: 85px;" class="mx-2" @click="tab = 'about'"
                      :color="tab === 'about' ? 'primary' : 'white_button'">О заказе
            </just-btn>
            <just-btn many style="min-width: 85px;" class="mx-2"
                      @click="tab = 'checks'"
                      :color="tab === 'checks' ? 'primary' : 'white_button'">Чеки
            </just-btn>
        </v-row>
        <div v-if="tab === 'goods'" class="mt-15">
            <order-items-list :read="true" :item="item"></order-items-list>
        </div>
        <div v-if="tab === 'about'" class="mt-15">
            <just-data-text label="Номер" :text="item.number"></just-data-text>
            <just-data-text label="Дата" :date="item.date"></just-data-text>
            <just-data-text label="Дата доставки" :date="item.delivery_date"></just-data-text>
            <just-data-text label="Компания" :text="item.company.name"></just-data-text>
            <just-data-text label="Касса" :text="item.checkout.name"></just-data-text>
            <just-data-text label="Клиент" :text="item.client.name"></just-data-text>
            <just-data-text label="Контакт" :text="item.contact.name"></just-data-text>
            <just-data-text label="Работник" :text="getWorkerFullName(item.worker.user)"></just-data-text>
        </div>
        <div v-if="tab === 'checks'" class="mt-15">
            <order-checks-list></order-checks-list>
        </div>
    </v-card>
</template>

<script>
import OrderChecksList from "../orders/orderCheck/OrderChecksList";
import OrderItemsList from "../orders/orderItems/OrderItemsList";

export default {
    name: "OrderInformation",
    data: () => ({
        tab: 'about',
    }),
    components: {
        OrderChecksList,
        OrderItemsList
    },
    computed: {
        item() {
            return this.$order.item
        }
    },
    created() {
        let query = {...this.$route.query}
        this.$order.loadItem(query.uuid)
    },
    methods: {
        getWorkerFullName(user) {
            return user.first_name + ' ' + user.last_name
        },
    }

}
</script>

<style>

.v-application {
    background: #f1f1f1 !important;
}

</style>