<template>
    <v-row no-gutters align="center" class="mt-5">
        <just-btn :loading="saveLoading" @click="saveItem">Сохранить</just-btn>
        <v-spacer></v-spacer>
        <just-btn v-if="model.item[model.pk]" outlined color="danger" :loading="deleteLoading" @click="deleteItem">
            Удалить
        </just-btn>
    </v-row>
</template>

<script>

import {handleError} from "../../../vendor/handle";

export default {
    name: 'AirItemActionsTemplate',
    components: {},
    props: {
        model: Object,
        valid: Boolean
    },
    data: () => ({
        saveLoading: false,
        deleteLoading: false
    }),
    methods: {
        saveItem() {
            this.saveLoading = true
            const {promise, created} = this.model.updateOrCreate(this.model.item)
            promise.then(res => {
                this.$snackbar.success('Сохранено')
                if (created)
                    this.$router.push({
                        name: `${this.model.name}Item`,
                        params: {
                            [this.model.routeParam]: res[this.model.pk]
                        }
                    }).catch(() => {
                    })
                this.saveLoading = false
            }).catch(() => {
                this.$snackbar.fail('Не удалось сохранить')
                this.saveLoading = false
            })
        },
        async deleteItem() {
            try {
                this.deleteLoading = true
                const res = await this.model.destroy()
                if (res) {
                    console.log(res);
                }
                this.$router.push({
                    name: `${this.model.name}List`
                }).catch(() => {
                })
                this.$snackbar.success('Удалено')
                this.deleteLoading = false
            } catch (e) {
                this.deleteLoading = false
                this.$snackbar.fail(handleError(e.data))
            }
        }
    }
}
</script>

<style scoped>

</style>