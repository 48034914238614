<template>
    <v-tab-item
        :key="2"
        :value="`tab-2`"
        class="pa-5">
        <v-card flat v-if="selectedCompany">
            <v-col class="mb-2" v-if="items.length > 1">
                <v-row no-gutters align="center" class="mb-3">
                    <div v-for="(el, index) in items" :key="index" class="pa-2">
                        <v-chip :color="el.company.inn === selectedCompany.company.inn ? 'primary' : ''"
                                @click="selectedCompany = el">
                            {{ el.company.name }}
                        </v-chip>
                    </div>
                </v-row>
                <v-col class="pa-0">
                    <report-view :item="selectedCompany.data"></report-view>
                </v-col>
            </v-col>
            <report-by-workers :items="selectedCompany.report_by_workers"></report-by-workers>
        </v-card>
    </v-tab-item>
</template>

<script>
import ReportByWorkers from "./ReportByWorkers";
import ReportView from "../ReportView";

export default {
    name: "reportByCompanies",
    components: {ReportByWorkers, ReportView},
    props: {
        items: Array
    },
    data: () => ({
        selectedCompany: null
    }),
    watch: {
        items() {
            if (this.items.length) {
                this.selectedCompany = this.items[0]
            } else
                this.selectedCompany = null
        }
    },
    created() {
        if (this.items.length) {
            this.selectedCompany = this.items[0]
        } else
            this.selectedCompany = null
    }
}
</script>

<style scoped>

</style>