<template>
    <div class="font-weight-bold title my-5"
         :class="{'subtitle-1': $vuetify.breakpoint.smAndDown}"
         style="line-height: 1.7rem">
        <slot/>
    </div>
</template>

<script>
export default {
    name: 'AirTitleText',
}
</script>
