import Vue from 'vue'

import {library} from '@fortawesome/fontawesome-svg-core'
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome'

import {
    faCubes,
    faShoppingCart,
    faUserCircle,
    faSearch,
    faPlus,
    faMinus,
    faInfoCircle,
    faWallet,
    faTruck,
    faAd,
    faKey,
    faAt,
    faTerminal,
    faSignature,
    faFileSignature,
    faFileContract,
    faSignInAlt,
    faCircle,
    faTruckLoading,
    faRubleSign,
    faEllipsisV,
    faTimes,
    faRedoAlt,
    faPhone,
    faDolly,
    faCog,
    faCaretUp,
    faCaretDown,
    faTrash,
    faCheckSquare,
    faHome,
    faFile,
    faFilter,
    faSignOutAlt, faBell, faCodeBranch,
    faCashRegister, faHandHoldingUsd, faCalendarCheck, faUserAlt, faUnlock, faLock, faChartArea
} from '@fortawesome/free-solid-svg-icons'

import {faBuilding, faSquare, faUser, faBookmark, faFileAlt, faEdit} from '@fortawesome/free-regular-svg-icons'

library.add(
    faUser,
    faCubes,
    faShoppingCart,
    faUserCircle,
    faSearch,
    faPlus,
    faMinus,
    faInfoCircle,
    faWallet,
    faTruck,
    faFileAlt,
    faAd,
    faKey,
    faAt,
    faTerminal,
    faSignature,
    faFileSignature,
    faFileContract,
    faSignInAlt,
    faCircle,
    faTruckLoading,
    faRubleSign,
    faEllipsisV,
    faTimes,
    faRedoAlt,
    faPhone,
    faDolly,
    faCog,
    faCaretUp,
    faCaretDown,
    faTrash,
    faCheckSquare,
    faSquare,
    faHome,
    faFile,
    faFilter,
    faSignOutAlt,
    faBell,
    faBuilding,
    faBookmark,
    faEdit, faCodeBranch,
    faCashRegister, faHandHoldingUsd, faCalendarCheck, faUserAlt,
    faUnlock, faLock, faChartArea
)

Vue.component('font-awesome-icon', FontAwesomeIcon)