<template>
    <v-menu
        ref="menu"
        v-model="menu"
        :close-on-content-click="false"
        :return-value.sync="date"
        transition="scale-transition"
        offset-y
        min-width="auto"
    >
        <template v-slot:activator="{ on, attrs }">
            <just-text-field
                v-model="date"
                :class="textFieldClasses"
                :value="date"
                :label="textFieldLabel"
                readonly
                @click="menuOn"
            ></just-text-field>
        </template>
        <v-date-picker
            v-model="date"
            locale="ru-RU"
            no-title
            scrollable
        >
            <v-spacer></v-spacer>
            <v-btn
                text
                color="primary"
                @click="menu = false"
            >
                Отмена
            </v-btn>
            <v-btn
                text
                color="primary"
                @click="$refs.menu.save(date)"
            >
                ОК
            </v-btn>
        </v-date-picker>
    </v-menu>

</template>

<script>
export default {
    name: 'JustDatePickerPopup',
    props: {
        label: String,
        textFieldLabel: String,
        textFieldClasses: String,
        textFieldVModel: String

    },
    data: () => ({
        menu: false,
        date: null
    }),
    methods: {
        closeDialog() {
            this.$emit('input', false)
            this.$emit('close')
            this.dialog = false
        },
        menuOn() {
            this.menu = true
        },

    },
    watch: {
        value(val) {
            if (val)
                setTimeout(() => {
                    this.dialog = true
                }, 50)
            else
                this.dialog = false
        }
    },
    computed: {
        dateText() {
            return this.date

        },
    },
    created() {
        this.dialog = this.value
    }
}
</script>

<style>


</style>