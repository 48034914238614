<template>
    <div class="text-h4 font-weight-bold py-5">
        <slot></slot>
    </div>
</template>

<script>
export default {
    name: 'TextHeader',
    data: () => ({}),
    methods: {}
}
</script>

<style scoped>

</style>