const m = require('air-vue-model/model')()
m.name = 'company'
m.url = 'companies'
m.pk = 'inn'

m.sno = [
    {value: 'osn', name: 'Общая СН'},
    {value: 'usn_income', name: 'Упрощенная СН (доходы)'},
    {value: 'usn_income_outcome', name: 'Упрощенная СН (доходы минус расходы)'},
    {value: 'envd', name: 'Единый налог на вмененный доход'},
    {value: 'esn', name: 'Единый сельскохозяйственный налог'},
    {value: 'patent', name: 'патентная СН'},
]

m.routes = [
    {name: 'list', component: require('../main/views/companies/Companies')},
    {name: 'item', component: require('../main/views/companies/Company'), single: true},
]
module.exports = m