<template>
  <air-item-template :model="$check">
    <template v-slot:form="{item}">
      <span v-model="item.check"></span>
<!--      <air-text-field label="Номер" class="mt-7" v-model="item.number" :rules="[$rules.required]"></air-text-field>-->
<!--      <air-text-field label="Дата" class="mt-7" v-model="item.date" :rules="[$rules.required]"></air-text-field>-->
<!--      <air-text-field label="Дата доставки" class="mt-7" v-model="item.delivery_date"-->
<!--                      :rules="[$rules.required]"></air-text-field>-->
<!--      <air-select v-if="!item.id" class="mt-2" item-text="name" item-value="inn" v-model="item.company"-->
<!--                  :items="$company.list"-->
<!--                  label="Компания"></air-select>-->
<!--      <air-select v-if="!item.id" class="mt-2" item-text="name" item-value="id" v-model="item.checkout"-->
<!--                  :items="$checkout.list"-->
<!--                  label="Касса"></air-select>-->
<!--      <air-select v-if="!item.id" class="mt-2" item-text="name" item-value="id" v-model="item.client"-->
<!--                  :items="$client.list" label="Клиент"></air-select>-->
<!--      <air-select v-if="!item.id" class="mt-2" item-text="name" item-value="id" v-model="item.contact"-->
<!--                  :items="$client.list"-->
<!--                  label="Контакт"></air-select>-->
<!--      <air-select v-if="!item.id" class="mt-2" item-text="id" item-value="id" v-model="item.worker"-->
<!--                  :items="$userCompany.list"-->
<!--                  label="Работник"></air-select>-->

    </template>
  </air-item-template>
</template>

<script>
import AirItemTemplate from "../../../components/template/item/AirItemTemplate";

export default {
  name: 'OrderCheck',
  components: {
    AirItemTemplate,
  },
  data: () => ({}),
  methods: {
    kek(val) {
      return moment.calendarDateTime(val)
    }
  },
  created() {
    this.$checkout.loadList()
    this.$client.loadList()
    this.$userCompany.loadList()
  }
}
</script>

<style scoped>

</style>

