import Vue from 'vue'

Vue.prototype.$user = Vue.observable(require('../models/user'))
Vue.prototype.$client = Vue.observable(require('../models/client'))
Vue.prototype.$nomenclature = Vue.observable(require('../models/nomenclature'))
Vue.prototype.$order = Vue.observable(require('../models/order'))
Vue.prototype.$company = Vue.observable(require('../models/company'))
Vue.prototype.$checkout = Vue.observable(require('../models/checkout'))
Vue.prototype.$userCompany = Vue.observable(require('../models/userCompany'))
Vue.prototype.$userDevice = Vue.observable(require('../models/userDevice'))
Vue.prototype.$check = Vue.observable(require('../models/orderChecks'))


Vue.prototype.$snackbar = Vue.observable(require('../vendor/snackbar'))
Vue.prototype.$auth = Vue.observable(require('../models/auth'))
Vue.prototype.$currency = Vue.observable(require('air-vue-model/currency'))
Vue.prototype.$rules = Vue.observable(require('air-vue-model/rules'))