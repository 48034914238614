<template>
    <v-col class="pa-0">
        <v-row class="mb-1" no-gutters>
            <v-card-title class="pa-0 mb-0 font-weight-bold">Пользователи</v-card-title>
            <v-spacer></v-spacer>
            <just-btn @click="openDialogWorker()">Добавить</just-btn>
        </v-row>
        <v-checkbox class="mb-4 mt-2" hide-details style="margin-left: -15px" v-model="showInactive"
                    label="Отображать неактивных пользователей"></v-checkbox>
        <v-col class="pa-0" :key="kek">
            <v-col class="pa-0 cursor-pointer"
                   v-for="(item, index) in currentWorkers" :key="index">
                <air-divider v-if="index" class="my-1"></air-divider>
                <v-row no-gutters @click="openDialogWorker(item)">
                    <v-col class="pa-0">
                        <v-row no-gutters align="center">
                            <v-icon size="15" :color="item.color" class="mr-2" v-if="item.role === 2">mdi-circle</v-icon>
                            <div class="d-inline-block my-1">{{ getWorkerFullName(item.user) }}</div>
                            <v-spacer></v-spacer>
                            <div class="caption">{{ item.role === 1 ? 'Администратор' : 'Работник' }}</div>
                        </v-row>
                        <div class="caption">{{ item.user.email }}</div>
                        <div class="caption" v-if="item.role === 2 && item.code">Код работника:
                            <span class="font-weight-bold">{{ item.code }}</span></div>
                    </v-col>
                    <air-icon v-if="!item.active" icon="lock" class="px-3 py-1"></air-icon>
                </v-row>
            </v-col>
        </v-col>
        <user-company-modal v-model="dialogUser" :item="selectedUser"></user-company-modal>
    </v-col>
</template>

<script>
import UserCompanyModal from "./UserCompanyModal";


export default {
    name: 'UserCompanyList',
    props: {},
    components: {
        UserCompanyModal,
    },
    data: () => ({
        dialogUser: false,
        selectedUser: null,
        showInactive: false,
        kek: 1
    }),
    methods: {
        openDialogWorker(item = null) {
            if (item) {
                this.selectedUser = this.$company.copy(item)
            } else {
                this.selectedUser = {
                    role: 2,
                    company: this.$company.item.inn,
                    code: null,
                    active: true,
                    user: {
                        first_name: null,
                        last_name: null,
                        password: null,
                        email: null
                    },
                    color: '#ff0000'
                }
            }
            this.dialogUser = true
        },
        getWorkerFullName(user) {
            return user.last_name + ' ' + user.first_name
        },

    },
    created() {
    },
    computed: {
        currentWorkers() {
            if (this.showInactive) {
                return this.$userCompany.list
            }
            return this.$userCompany.list.filter(val => val.active)
        }
    },
    watch: {
        '$userCompany.list'() {
            this.kek += 1
        }
    }
}
</script>

<style scoped>

</style>