<template>
    <v-row no-gutters>
        Не найдено
    </v-row>
</template>

<script>
export default {
    name: 'NotFound',
    data: () => ({
        
    }),
    methods: {
        
    }
}
</script>

<style scoped>

</style>