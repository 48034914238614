<template>
    <div>
        <v-checkbox class="mt-0" hide-details v-model="hideZeroRows" label="Скрыть пустые записи"></v-checkbox>
        <v-checkbox hide-details class="mt-2 mb-5" v-model="onlyWorkers"
                    label="Отображать только работников"></v-checkbox>
        <v-data-table
            dense
            :headers="headers"
            :items="currentItems"
            :items-per-page="999999"
            hide-default-footer
            item-key="name"
            @click:row="selectRow"
            locale="ru-RU"
            noDataText="Работников нет"
            noResultsText="Работников не найдено"
        >
            <template v-slot:item.worker="{item}">
                <div v-if="item.worker && item.worker.user">{{ item.worker.user.first_name }}
                    {{ item.worker.user.last_name }}
                </div>
            </template>
            <template v-slot:item.cash="{item}">
                {{ item.data.total.cash / 100 }}
            </template>
            <template v-slot:item.non_cash="{item}">
                {{ item.data.total.non_cash / 100 }}
            </template>
        </v-data-table>
        <air-dialog v-if="item" v-model="dialog">
            <div class="mb-3 font-weight-bold" v-if="item.worker && item.worker.user">{{ item.worker.user.first_name }}
                {{
                    item.worker.user.last_name
                }}
            </div>
            <report-view :item="item.data"></report-view>
        </air-dialog>
    </div>
</template>

<script>
import ReportView from "../ReportView";

export default {
    name: "reportByWorkers",
    props: {
        items: Array
    },
    components: {
        ReportView
    },
    data: () => ({
        hideZeroRows: true,
        onlyWorkers: true,
        dialog: false,
        item: null,
        headers: [
            {text: 'Курьер', value: 'worker'},
            {text: 'Успешные чеки', value: 'data.done_checks_count'},
            {text: 'Чеки в ожидании', value: 'data.waiting_checks_count'},
            {text: 'Чеки с ошибкой', value: 'data.failed_checks_count'},
            {text: 'Наличные', value: 'cash'},
            {text: 'Безналичные', value: 'non_cash'},
        ],
    }),
    computed: {
        currentItems() {
            let currentList = this.items
            if (this.hideZeroRows) {
                currentList = currentList.filter(val => val.data.done_checks_count > 0
                    || val.data.waiting_checks_count > 0 || val.data.failed_checks_count > 0)
            }
            if (this.onlyWorkers) {
                currentList = currentList.filter(val => val.worker && val.worker.role === 2)
            }
            return currentList
        }
    },
    methods: {
        selectRow(el) {
            this.item = el
            setTimeout(() => {
                this.dialog = true
            }, 50)
        },
        getWorkerFullName(item) {
            return item.first_name + ' ' + item.last_name
        }
    }

}
</script>

<style scoped>

</style>